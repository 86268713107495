import React, { useEffect, useState, useCallback, useRef, useContext } from "react"
import { navigate, Link } from "gatsby"
import debounce from "lodash/debounce"
import Loadable from "@loadable/component"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import FormControl from "react-bootstrap/FormControl"
import Modal from "react-bootstrap/Modal"
import Dropdown from "react-bootstrap/Dropdown"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Spinner from "react-bootstrap/Spinner"
import Card from "react-bootstrap/Card"
import InputGroup from "react-bootstrap/InputGroup"
import Loader from "../custom/Loader"
import Btn from "../custom/Button"
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"

import SEO from "../../components/seo"
import authClient from "../../services/auth"
import { isBrowser } from "../../services/developer_mode"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Sidebar from "../../components/Sidebar/template-sidebar"
import UploadFiles from "./upload-files2"
import Datetime from "react-datetime"
import utcDateTime from "../../services/utcdatetime"
import CohortSchedule from "./cohort_schedule"
import TestBreakoutSession from "../template-resources/breakout-session"
import BreakoutSessionSection from "./breakout-session-section"
import Blog from "../blogs/create-edit"
import { DescriptionInputField as TextareaDescriptionField } from "../trainings/training-content"
import LayoutHeader from "../layoutHeader"
import Image from "../custom/Image"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleDown,
  faClock,
  faCommentAlt,
  faUsers,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronRight,
  faCheck,
  faChevronLeft,
  faSpinner,
  faImage,
  faLink,
  faVideo,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons"

import DragHandleIcon from "../../images/svgs/drag-handle-gray.svg"
import AddDescriptionIcon from "../../images/svgs/adddescriptionicon.svg"
import AddedDescriptionIcon from "../../images/svgs/addeddescriptionicon.svg"
import DeleteIcon from "../../images/svgs/delete.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quiz from "../../images/svgs/quiz.svg"
import TrashCan from "../../images/svgs/trash-can-transparent.svg"
import BreakoutGroupsCreation from "../../images/svgs/breakout_groups_creation.svg"
import CancelWhite from "../../images/svgs/close.svg"
import WorkshopInfo from "../../images/svgs/live_workshop_info.svg"
// import WhiteVideoIcon from '../../images/svgs/video-white-icon.svg'
import WhiteBlogIcon from "../../images/svgs/blog-white-icon.svg"
import WhiteQuizIcon from "../../images/svgs/quiz-white-icon.svg"
import whiteMatchingIcon from "../../images/svgs/whiteMatchingIcon.svg"
import WhitePollIcon from "../../images/svgs/polls-white-icon.svg"
import ResourceColorIcon from "../../images/svgs/resource-multicolor-icon.svg"
import documents from "../../images/svgs/documentresWhite.svg"

import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import pdf from "../../images/svgs/pdf.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"

import StylesA from "../template-resources/breakout-session.module.scss"
import Styles from "../../modularscss/styles.module.scss"
import StylesQ from "../quizzes/quiz.module.scss"
import StylesB from "./course.module.scss"
import StylesM from "../../components/upload-resource.module.scss"
import ActivityRoomWokSpace from "../../components/mural/mural-room-workspace"
import config from "../mural/config"
import refreshToken from "../mural/refreshToken"
import SunEditor from "suneditor-react"
import CloseBtn from "../close-btn"
import { COURSES } from "../../constants/permissionsConstant"
import { HasReadPermission, HasEditPermission } from "../../utils/PermissionDataAccess"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"
const WorkSpaceRoom = Loadable(() => import("../mural/mural-room-workspace/workspaceRoom"))

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 12px 0px",
  border: "1px solid transparent",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#303C54",
  boxShadow: "0px 3px 6px #00000029",

  // styles we need to apply on draggables
  ...draggableStyle,
})
const getTopicItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  background: "#42506C",
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})
const getResourceItemStyle = (isDragging, draggableStyle) => ({
  border: "1px solid transparent",
  userSelect: "none",
  outline: "none",
  margin: "0px 0px 8px 0px",
  borderRadius: "8px",
  color: "#E0E2E5",
  // background: '#42506C',
  boxShadow: "0px 3px 6px #00000029",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  background: "#212C42",
  padding: "12px",
  borderRadius: "0px 0px 8px 8px",
  // maxHeight: 'calc(100vh - 160px)',
  overflowY: "auto",
})
const getTopicListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#000" : "#12172c",
  // background: '#212C42',
  // padding: grid,
})

export const NewCheckBox = (props) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        props.changeInParent(props.change_value)
      }}
      className={`d-flex mb-2 br-9px p-3 inner-element-box-shadow pointer border_on_hover text-color-1 ${props.checked ? StylesB.bg_4 : StylesB.bg_3}`}
      style={{ border: "1px solid transparent" }}
    >
      <div
        className="text-center d-flex align-items-center justify-content-center mr-2"
        style={{
          backgroundColor: "#303C54",
          borderRadius: "50%",
          minWidth: "35px",
          border: "1px solid white",
          height: "35px",
        }}
      >
        {props.checked ? <FontAwesomeIcon style={{ verticalAlign: "bottom", fontSize: "13px" }} icon={faCheck} /> : ""}
      </div>
      <div className={`ml-2 align-self-center fs-14px ${!props.checked ? "opacity-69" : ""}`}>{props.children}</div>
    </div>
  )
}
const WeightageField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.weightage)
  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)

  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  return (
    <Dropdown className={"session-time-drop-down col-3 px-0 ml-2"}>
      <Dropdown.Toggle as="div" id="dropdown-custom-components" className={`${!isPermitted && "disable-content"}`}>
        <div
          className="px-0"
          style={{
            position: "relative",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "8px",
            background: props.topic_module.module_type === "5" ? "#384257" : "#42506C",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "text",
            }}
            className={`${StylesB.session_input_field} ${StylesB.session_number_field} border_on_hover px-2 ${Styles.font_16}`}
          >
            Weightage: {inputValue}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={`${StylesB.active_slider_drop_down}`}>
        <input
          autoComplete="off"
          type="range"
          min="0"
          max="100"
          name="weightage"
          autoFocus
          id={props.datum.id + `_topic_weightage`}
          value={inputValue}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
            delayedQuery("weightage", e.target.value, props.datum, "topic")
          }}
          placeholder={"Weightage"}
          className={`${StylesB.topic_weightage_slider} ${Styles.font_16}`}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

const BreakOutDurationInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [timeType, setTimeType] = useState("Mins")
  const [inputValue, setInputValue] = useState(props.datum.duration)

  useEffect(() => {
    if (inputValue !== props.datum.duration) {
      let current_value = timeType === "Mins" ? inputValue : inputValue * 60
      props.handleDurationSave(props.datum, current_value, "duration", "module")
    }
  }, [inputValue])

  return (
    <div className="col-3 px-0 mr-2" style={{ position: "relative" }}>
      {showDropDown ? (
        <input
          autoComplete="off"
          type="number"
          min="1"
          name="duration"
          autoFocus
          id={props.datum.id + `_module_duration`}
          value={inputValue}
          onChange={(e) => {
            e.preventDefault()
            setInputValue(e.target.value)
          }}
          placeholder={"Session duration"}
          className={`${StylesA.session_input_field} ${StylesA.session_number_field} form-control border-0 px-3 ${Styles.font_16}`}
          onClick={() => setShowDropDown(!showDropDown)}
          onBlur={() => setShowDropDown(false)}
          required
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
            cursor: "text",
          }}
          onClick={() => setShowDropDown(!showDropDown)}
          className={`${StylesA.session_input_field} ${StylesA.session_number_field} px-2 ${StylesA.border_on_hover} ${Styles.font_16}`}
        >
          Duration: {inputValue} {timeType}
        </div>
      )}
      <Dropdown.Menu show={showDropDown} className={`${showDropDown ? StylesA.active_input_drop_down : ""}`}>
        {timeType === "Mins" ? (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(10)
                setShowDropDown(false)
              }}
            >
              <p>10 mins</p>
            </div>
            <div
              onClick={() => {
                setInputValue(20)
                setShowDropDown(false)
              }}
            >
              <p>20 mins</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(1)
                setShowDropDown(false)
              }}
            >
              <p>1 hrs</p>
            </div>
            <div
              onClick={() => {
                setInputValue(2)
                setShowDropDown(false)
              }}
            >
              <p>2 hrs</p>
            </div>
          </React.Fragment>
        )}
        <div
          style={{
            fontSize: "12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <p onClick={() => setTimeType("Mins")} style={{ opacity: timeType === "Mins" ? 1 : 0.5 }}>
            Mins
          </p>
          <p
            onClick={() => setTimeType("Hours")}
            style={{
              marginLeft: "10px",
              opacity: timeType === "Hours" ? 1 : 0.5,
            }}
          >
            Hours
          </p>
        </div>
      </Dropdown.Menu>
    </div>
  )
}

const DurationInputField = (props) => {
  const [showDropDown, setShowDropDown] = useState(false)
  const [timeType, setTimeType] = useState("Mins")
  const [inputValue, setInputValue] = useState(props.datum.duration)
  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)

  useEffect(() => {
    if (inputValue !== props.datum.duration) {
      let current_value = timeType === "Mins" ? inputValue : inputValue * 60
      props.handleSave("duration", current_value, props.datum, "topic")
    }
  }, [inputValue])
  return (
    <Dropdown className={"session-time-drop-down col-3 px-0"}>
      <Dropdown.Toggle as="div" id="dropdown-custom-components" className={`${!isPermitted && "disable-content"}`}>
        <div
          className="px-0"
          style={{
            position: "relative",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "8px",
            background: props.topic_module.module_type === "5" ? "#384257" : "#42506C",
          }}
        >
          {showDropDown ? (
            <input
              type="number"
              min="1"
              name="duration"
              autoFocus
              autoComplete="off"
              id={props.datum.id + `_module_duration`}
              value={inputValue}
              onChange={(e) => {
                e.preventDefault()
                setInputValue(e.target.value)
              }}
              placeholder={"Session duration"}
              className={`${StylesB.session_input_field} ${StylesB.session_number_field} form-control border-0 px-3 ${Styles.font_16}`}
              onClick={(e) => {
                e.stopPropagation()
                setShowDropDown(!showDropDown)
              }}
              onBlur={() => setShowDropDown(false)}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "text",
              }}
              onClick={() => {
                setShowDropDown(!showDropDown)
              }}
              className={`${StylesB.session_input_field} ${StylesB.session_number_field} border_on_hover px-2 ${Styles.font_16}`}
            >
              Duration: {inputValue} {timeType}
            </div>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu show={showDropDown} className={`${StylesB.active_input_drop_down}`}>
        {timeType === "Mins" ? (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(10)
              }}
            >
              <p>10 mins</p>
            </div>
            <div
              onClick={() => {
                setInputValue(20)
              }}
            >
              <p>20 mins</p>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                setInputValue(1)
              }}
            >
              <p>1 hrs</p>
            </div>
            <div
              onClick={() => {
                setInputValue(2)
              }}
            >
              <p>2 hrs</p>
            </div>
          </React.Fragment>
        )}
        <div
          style={{
            fontSize: "12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <p onClick={() => setTimeType("Mins")} style={{ opacity: timeType === "Mins" ? 1 : 0.5 }}>
            Mins
          </p>
          <p
            onClick={() => setTimeType("Hours")}
            style={{
              marginLeft: "10px",
              opacity: timeType === "Hours" ? 1 : 0.5,
            }}
          >
            Hours
          </p>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const SessionTimingField = (props) => {
  const [startDate, setStartDate] = useState(props.datum.start_date ? new Date(props.datum.start_date) : "")
  const [endDate, setEndDate] = useState(props.datum.end_date ? new Date(props.datum.end_date) : "")
  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)

  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
    callValidation()
  }

  const delayedQuery = useCallback(
    debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )
  const delayedQuery1 = useCallback(
    debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  function callValidation() {
    if (props.moduleErrors[props.datum.id]) {
      props.validateModule(props.datum, false)
    }
  }

  function showError() {
    if (props.moduleErrors[props.datum.id]) {
      return (
        <div className={`text-left pl-1 d-flex align-items-baseline`}>
          <FontAwesomeIcon className="mt-1 mr-1" icon={faExclamationTriangle} color="#F4F411" size="sm" style={{ height: "10px" }} />
          <p className="mb-0" style={{ fontSize: "13px", color: "#F4F411" }}>
            {props.moduleErrors[props.datum.id]}
          </p>
        </div>
      )
    } else return null
  }

  let start_date = startDate ? utcDateTime.utcDateTime(startDate, "time") : "00:00"
  let end_date = endDate ? utcDateTime.utcDateTime(endDate, "time") : "00:00"
  return (
    <div>
      <Dropdown className={"session-time-drop-down"}>
        <Dropdown.Toggle as="div" id="dropdown-custom-components" className={`${!isPermitted && "disable-content"}`}>
          <div className={`px-0 ${props.datum.module_type === "5" ? `ml-auto font-weight-bold` : ``}`} style={{ position: "relative", minWidth: "300px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
                cursor: "text",
              }}
              className={`${StylesB.session_input_field} ${StylesB.session_number_field} ${props.datum.module_type !== "5" ? "border_on_hover" : ""} px-2 ${
                Styles.font_16
              }`}
            >
              {props.datum.module_type === "5" ? "" : "Session "}Timings: {start_date} - {end_date}
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${StylesB.active_calendar_drop_down}`}>
          <Col lg="12" className="d-flex" style={{ padding: "12px" }}>
            <Col lg="6" className="pl-0" style={{ paddingRight: "6px" }}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#E0E2E5",
                  marginBottom: "0px",
                }}
              >
                From
              </p>
              <Datetime
                input={false}
                dateFormat={false}
                timeFormat="hh:mm A"
                closeOnSelect={true}
                onChange={(e) => {
                  setStartDate(e)
                  delayedQuery("start_date", e, props.datum, "module")
                }}
                value={startDate}
                inputProps={{
                  placeholder: "Start time",
                  className: "video-modal p-0",
                }}
                style={{
                  background: "inherit",
                  color: "inherit",
                  height: "100%",
                }}
                className={`session_start_time_picker blue-bg video-modal`}
              />
            </Col>
            <Col lg="6" className="pr-0" style={{ paddingLeft: "6px" }}>
              <p
                style={{
                  fontSize: "12px",
                  color: "#E0E2E5",
                  marginBottom: "0px",
                }}
              >
                To
              </p>
              <Datetime
                input={false}
                dateFormat={false}
                timeFormat="hh:mm A"
                closeOnSelect={true}
                onChange={(e) => {
                  setEndDate(e)
                  delayedQuery1("end_date", e, props.datum, "module")
                }}
                value={endDate}
                inputProps={{
                  placeholder: "End time",
                  className: "video-modal p-0",
                }}
                style={{
                  background: "inherit",
                  color: "inherit",
                  height: "100%",
                }}
                className={`session_start_time_picker blue-bg video-modal`}
              />
            </Col>
          </Col>
        </Dropdown.Menu>
      </Dropdown>
      {showError()}
    </div>
  )
}

export const DescriptionInputField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum?.description ? props.datum?.description : "")
  const [loader, setLoader] = useState(false)
  const heading =
    props.type === "breakout-activity"
      ? "Add Instructions"
      : props?.selectedStageName === "Workshop" && props?.course?.course_type !== "1"
      ? "Add Session Objective"
      : "Add Module Description"

  useEffect(() => {
    props.moduleEdited && setLoader(false)
  }, [props.moduleEdited])

  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )
  return (
    <div>
      <div className={`w-100 mb-2 d-inline-flex align-items-center`}>
        <p className={`mb-0 text-E0E2E5 fs-16px font-weight-bold`}>{heading}</p>
        <img onClick={() => props.showModalHandler(false)} src={CancelWhite} alt={`Cancel`} className={`ml-auto mb-0 pointer object-fit-cont`} height="13px" />
      </div>
      <div
        className={`${props.datum.module_type === "5" ? StylesB.break_description_input : StylesB.description_input} px-0 border_on_hover br-9px box-shadow ${
          !props?.isPermitted && "disable-content"
        }`}
      >
        <SunEditor
          id={props.datum.id + "_description"}
          name="description"
          placeholder=""
          defaultValue={inputValue}
          onChange={(content) => {
            setInputValue(content)
            setLoader(true)
            delayedQuery("description", content, props.datum, props.type || "module")
          }}
          setOptions={{
            fontSize: 18,
            fontColor: "#fff",
            height: "auto",
            maxHeight: "200px",
            minHeight: "200px",
            backgroundColor: "#212C42",
            buttonList: [["align", "list", "font", "fontSize", "bold", "underline", "italic", "link", "removeFormat"]],
          }}
          disable={!props?.isPermitted}
        />
      </div>
      {props?.isPermitted && (
        <div className={`w-100 d-inline-flex mt-3`}>
          <Btn className={`px-4 py-1 float-left border_on_hover`} onClick={() => props.showModalHandler(false)}>
            Done
          </Btn>
          <div className="d-flex ml-auto pr-3 align-items-center">
            {loader ? (
              <>
                <Loader />
                &nbsp;
                <p className="text-E0E2E5 fs-14px m-0">Saving</p>
              </>
            ) : (
              <>
                <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer object-fit-contain`} width="16px" />
                <p className="text-E0E2E5 fs-14px m-0">All changes are saved</p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const NameInputField = (props) => {
  const [inputValue, setInputValue] = useState(props.datum.name)
  const [loader, setLoader] = useState(false)
  const [showInputField, setShowInputField] = useState(false)
  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)

  useEffect(() => {
    if (props.moduleEdited) {
      setLoader(false)
    }
  }, [props.moduleEdited])

  function sendQuery(name, value, datum, type) {
    props.handleSave(name, value, datum, type)
  }

  const delayedQuery = useCallback(
    debounce((name, value, datum, type) => sendQuery(name, value, datum, type), 1000),
    []
  )

  function showModuleName(datum) {
    if (props.selectedStageName === "Workshop") {
      let start_date = datum.start_date ? utcDateTime.utcDateTime(datum.start_date, "time") : "00:00"
      let end_date = datum.end_date ? utcDateTime.utcDateTime(datum.end_date, "time") : "00:00"
      if (props.course.course_type === "0") {
        return `${datum.name} (${start_date} -  ${end_date}, Day ${datum.course_day})`
      } else if (props.course.course_type === "3") {
        return `${datum.name} (${start_date} -  ${end_date})`
      } else {
        return `${datum.name}`
      }
    } else {
      return `${datum.name}`
    }
  }
  function showTopicName(datum) {
    if (props.course.course_type !== "1" && props.selectedStageName === "Workshop") {
      return `${datum.name} (${datum.duration} Mins)`
    } else {
      return `${datum.name}`
    }
  }

  if (props.type === "module") {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        {showInputField || props.datum.module_type === "5" ? (
          <input
            autoComplete="off"
            type="text"
            name="name"
            id={props.datum.id + `_module_input_name`}
            onBlur={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(false)
            }}
            autoFocus={props.datum.module_type === "5" ? false : true}
            defaultValue={props.datum.name}
            onChange={(e) => {
              e.preventDefault()
              setInputValue(e.target.value)
              setLoader(true)
              delayedQuery(e.target.name, e.target.value, props.datum, props.type)
            }}
            readOnly={!isPermitted}
            placeholder={"Enter Name"}
            className={`${StylesB.session_input_field} ${!isPermitted && "disable-content"} form-control px-2 font-weight-bold ${
              Styles.font_16
            } d-inline-flex w-100`}
            required
          />
        ) : (
          <p
            id={props.datum.id + `_module_input_text`}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(true)
            }}
            className={`${StylesB.activity_input_field} d-inline-flex w-100 align-items-center font-weight-bold form-control py-0 border-0 px-2 my-1 ${Styles.font_16}`}
            style={{ minHeight: `38px`, cursor: "text" }}
          >
            {showModuleName(props.datum)}
          </p>
        )}
        {loader ? (
          <svg
            className="animate-spin my-auto"
            style={{
              height: `16px`,
              width: `16px`,
              position: "absolute",
              top: "10px",
              right: "0px",
              zIndex: 3,
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : null}
      </div>
    )
  } else {
    return (
      <div style={{ width: "100%", position: "relative" }}>
        {showInputField ? (
          <input
            autoComplete="off"
            type="text"
            name="name"
            id={props.datum.id + `_topic_input_name`}
            onBlur={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(false)
            }}
            defaultValue={props.datum.name}
            onChange={(e) => {
              e.preventDefault()
              setInputValue(e.target.value)
              setLoader(true)
              delayedQuery(e.target.name, e.target.value, props.datum, props.type)
            }}
            autoFocus
            placeholder={"Activity name"}
            readOnly={!isPermitted}
            className={`${StylesB.activity_input_field} ${!isPermitted && "disable-content"} font-weight-bold form-control border-0 px-2 ${Styles.font_16}`}
            required
          />
        ) : (
          <p
            id={props.datum.id + `_topic_input_text`}
            style={{ cursor: "text" }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowInputField(true)
            }}
            className={`${StylesB.activity_input_field} d-inline-flex w-100 align-items-center font-weight-bold form-control border-0 px-2 my-2 ${Styles.font_16}`}
          >
            {showTopicName(props.datum)}
          </p>
        )}
        {loader ? (
          <svg
            className="animate-spin my-auto"
            style={{
              height: `16px`,
              width: `16px`,
              position: "absolute",
              top: "10px",
              right: "0px",
              zIndex: 3,
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : null}
      </div>
    )
  }
}

const CourseContent = (location, props) => {
  const DefaultHeader = isBrowser()
    ? {
        authorization: "Token " + localStorage.getItem("student_authenticity_token"),
      }
    : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [course, setCourse] = useState({ company: location.company_id })
  const [courseName, setCourseName] = useState("")
  const [course_template, setCourseTemplate] = useState("")
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [width, setWidth] = useState(1000)
  const isPermitted = HasEditPermission(COURSES) && HasReadPermission(COURSES)

  // to delete modal of module

  const [selectSequecing, setSelectSequecing] = useState(false)
  const [hashValue, setHashValue] = useState(
    location.props.location.pathname.indexOf("templates/template-content") > -1 ? `real` : location.props.location.hash.replace(`#`, "")
  )

  const [showOnlyWorkshop, setShowOnlyWorkshop] = useState(false)

  const [selectedStage, setSelectedStage] = useState("")
  const [selectedStageName, setSelectedStageName] = useState("")
  const [modules, setModules] = useState({})
  const [moduleCreated, setModuleCreated] = useState(true)
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedModuleToggle, setSelectedModuleToggle] = useState({})
  const [selectedModuleToggleChange, setSelectedModuleToggleChange] = useState(false)
  const [dmodal, setDmodal] = useState(false)
  const [selectedDeletingModule, setSelectedDeletingModule] = useState([])
  const [selectedDeletingTopic, setSelectedDeletingTopic] = useState([])
  const [modulesData, setModulesData] = useState({})
  const [currentModuleFetched, setCurrentModuleFetched] = useState(false)
  const [currentModuleTopicsFetched, setCurrentModuleTopicsFetched] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedTopicToggle, setSelectedTopicToggle] = useState({})
  const [selectedTopicToggleChange, setSelectedTopicToggleChange] = useState(false)
  const [topicCreated, setTopicCreated] = useState(true)
  const [visibleTopicData, setVisibleTopicData] = useState({})
  const [visibleTopicDataFetched, setVisibleTopicDataFetched] = useState(false)
  const [deleteVisibleResource, setDeleteVisibleResource] = useState([])

  const [modulesResources, setModulesResources] = useState({})
  const [modulesResourcesNext, setModulesResourcesNext] = useState({})
  const [modulesResourcesFetched, setModulesResourcesFetched] = useState(false)
  const [modulesResourcesFetchedx, setModulesResourcesFetchedx] = useState(true)
  const [topicsResources, setTopicsResources] = useState({})
  const [topicsResourcesNext, setTopicsResourcesNext] = useState({})
  const [topicsResourcesNextLoader, setTopicsResourcesNextLoader] = useState({})
  const [topicsResourcesFetched, setTopicsResourcesFetched] = useState(false)
  const [topicsResourcesFetchedex, setTopicsResourcesFetchedex] = useState({})
  const [topicsResourcesFetchedxx, setTopicsResourcesFetchedxx] = useState(true)
  const [resourceDeleted, setResourceDeleted] = useState(true)
  const [collapse, setCollapse] = useState(false)

  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)
  const [topicFetched, setTopicFetched] = useState(false)
  const [topicsData, setTopicsData] = useState({})
  const [sequenceType, setSequenceType] = useState("")
  const [preworkshopSeleted, setPreworkshopSeleted] = useState(false)
  const [postworkshopSeleted, setPostworkshopSeleted] = useState(false)
  const [showWorkshopInfo, setShowWorkshopInfo] = useState(false)

  const [allResourcesChanged, setAllResourcesChanged] = useState(false)
  const [moduleEdited, setModuleEdited] = useState(false)
  const [courseDays, setCourseDays] = useState(0)
  const [currentInputField, setCurrentInputField] = useState("")
  const [leftNavOpen, setLeftNavOpen] = useState(false)
  const [activeResourceType, setActiveResourceType] = useState("")
  const [activeNavItem, setActiveNavItem] = useState("")

  /*for system files uploading start*/
  const [highlight, setHighlight] = useState("unhighlight")
  const [files, setFiles] = useState([])
  const [refetched, setRefetched] = useState(false)
  const [hovered, setHovered] = useState(false)

  //for cohort schedule update
  const [showScheduleUpdateWarning, setShowScheduleUpdateWarning] = useState(true)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showCohortSchedule, setShowCohortSchedule] = useState(false)
  const [initiateScheduleValidation, setInitiateScheduleValidation] = useState(false)
  const [validCohortSchedule, setValidCohortSchedule] = useState(false)
  const [moduleErrors, setModuleErrors] = useState({})

  const [showSuggestDayModal, setShowSuggestDayModal] = useState(false)
  const [recentDay, setRecentDay] = useState(1)

  const [showAddDescriptionmodal, setShowAddDescriptionModal] = useState(false)
  const [activeDatum, setActiveDatum] = useState(null)

  // for breakout session creation and edit
  const [currentNavTab, setCurrentNavTab] = useState("")
  const [dragEnded, setDragEnded] = useState(false)
  const [draggedItem, setDraggedItem] = useState("")
  const [currentSelectedResourcesType, setCurrentSelectedResourcesType] = useState("")
  const [activeSection, setActiveSection] = useState("Home")
  const [createBreakoutSessionOpened, setCreateBreakoutSessionOpened] = useState(false)
  const [selectedTopicForBreakout, setSelectedTopicForBreakout] = useState("")
  const [selectedBreakoutForEdit, setSelectedBreakoutForEdit] = useState("")
  const [createdBreakoutSession, setCreatedBreakoutSession] = useState("")
  const [showAllBreakoutSessions, setShowAllBreakoutSessions] = useState(false)

  const [uploadInTopic, setUploadInTopic] = useState(null)
  const [uploadInModule, setUploadInModule] = useState(null)
  const [uploadDataSequence, setUploadDataSequence] = useState(null)

  const [addBlog, setAddBlog] = useState(false)
  const [showPollModal, setShowPollModal] = useState(false)
  const [showPopQuizModal, setShowPopQuizModal] = useState(false)
  const [showSection, setShowSection] = useState("video")

  const [showCommonItemsModal, setShowCommonItemsModal] = useState(false)
  const [openInstructions, setOpenInstructions] = useState(false)
  const [openAddLinks, setOpenAddLinks] = useState(false)
  const [openAddMedia, setOpenAddMedia] = useState(false)
  const [openAddQuizzes, setOpenAddQuizzes] = useState(false)
  const [openAddChecklists, setOpenAddCheckLists] = useState(false)

  const quizWin = useRef(null)
  const checklistWin = useRef(null)
  const casestudyWin = useRef(null)
  const mtfWin = useRef(null)
  const [attrForResources, setAttrForResources] = useState(null)
  const [addResourceType, setAddResourceType] = useState("")
  const [notificationData, dispatch] = useContext(GlobalContext)

  const [disabledResources, setDisabledResources] = useState([])
  const disabledResourceType = ["mtf", "quiz", "Poll"]
  const isMuralEnabled = notificationData.appData.data?.company?.mural_enabled && !(course.course_type === "1")

  function sendQuery(value, template, datum, index, type, group_id, instructions) {
    if (type === "group_updation") {
      updateBreakoutGroup(value, datum.id, index, instructions)
    } else {
      updateCommomResourceInstruction(value, template, datum, index, type, group_id, instructions)
    }
  }

  const delayedQuery = useCallback(
    debounce((value, template, datum, index, type, group_id, instructions) => sendQuery(value, template, datum, index, type, group_id, instructions), 1000),
    []
  )

  useEffect(() => {
    if (activeNavItem === "" && activeResourceType === "") openNavWithResourceSection("Resource", "video")
  })

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener("message", handleNewWinMessages, false)
      window.addEventListener("close-poll-modal", handlePollModalMsgs, false)
      window.addEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs, false)

      return () => {
        window.removeEventListener("message", handleNewWinMessages)
        window.removeEventListener("close-poll-modal", handlePollModalMsgs)
        window.removeEventListener("close-pop-quiz-modal", handlePopQuizModalMsgs)
      }
    }
  }, [window])

  const [breakoutGroupList, setBreakoutGroupList] = useState([])
  const [breakoutGroupsDataFetched, setBreakoutGroupsDataFetched] = useState(false)
  const [showCreatingGroupsModal, setShowCreatingGroupsModal] = useState(false)
  const [createdGroup, setCreatedGroup] = useState(true)
  const [showInstructionCreatingGroupsModal, setShowInstructionCreatingGroupsModal] = useState(false)
  const [refetchGroups, setRefetchGroups] = useState(false)

  const [breakoutGroupsResourcesFetched, setBreakoutGroupsResourcesFetched] = useState(false)
  const [breakoutGroupResources, setBreakoutGroupResources] = useState([])
  const [breakoutGroupInstructionsFetched, setBreakoutGroupInstructionsFetched] = useState(false)
  const [breakoutGroupInstructions, setBreakoutGroupInstructions] = useState([])
  const [instructionsLoader, setInstructionsLoader] = useState({})

  useEffect(() => {
    if (activeNavItem === "" && activeResourceType === "") openNavWithResourceSection("Resource", "video")
  })

  useEffect(() => {
    if (validCohortSchedule) {
      handleSubmit(null, true)
      handleScheduleModalClose()
    }
  }, [validCohortSchedule])

  useEffect(() => {
    if (activeNavItem === "BreakoutSessions") {
      setCreateBreakoutSessionOpened(true)
      setShowAllBreakoutSessions(true)
    }
  }, [activeNavItem])

  useEffect(() => {
    if (showCommonItemsModal) {
      setAddResourceType("common")
    } else {
      setAddResourceType("")
    }
  }, [showCommonItemsModal])

  function onDragEnter(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragOver(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("highlight")
  }

  function onDragLeave(event) {
    event.preventDefault()
    event.stopPropagation()
    setHighlight("unhighlight")
  }

  function onDrop(event, module_id, id, data) {
    event.preventDefault()
    event.stopPropagation()
    setUploadInTopic(id)
    setUploadInModule(module_id)
    setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))
    setHighlight("unhighlight")
    setShowSection("video")
    handleDrop(event)
  }

  function handleDrop(e) {
    var dt = e.dataTransfer
    let new_files = dt.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: new_files[i].name + " has size more than 200MB." },
        })
      }
    }
  }

  function validateAndAdd(event) {
    let new_files = event.target.files
    let filex = []
    for (let i = 0; i < new_files.length; i++) {
      if (parseInt(new_files[i].size) < 200000000) {
        filex.push(new_files[i])
        setFiles([...files, ...filex])
      } else {
        dispatch({
          type: SET_NOTIFICATION_DATA,
          payload: { type: "error", title: new_files[i].name + " has size more than 200MB." },
        })
      }
    }
  }
  /*for system files uploading end*/

  const handleClose = () => {
    setDmodal(false)
    setSelectedDeletingModule([])
    setSelectedDeletingTopic([])
    setDeleteVisibleResource([])
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  useEffect(() => {
    if (location?.props?.location?.state?.from_create && course_template?.stages?.length > 0 && !course_template?.stages[0]?.modules?.length > 0) {
      setShowWorkshopInfo(true)
    }
  }, [course_template?.stages])

  useEffect(() => {
    if (isBrowser()) {
      setWidth(window.innerWidth)
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
      return () => {
        window.removeEventListener("resize", () => {})
      }
    }
  }, [])

  useEffect(() => {
    if (createdBreakoutSession && selectedTopicForBreakout) {
      let current_data = { ...topicsData }
      current_data["topic_" + selectedTopicForBreakout].breakout_sessions.push(createdBreakoutSession)
      setTopicsData(current_data)
      handleCloseBreakoutOverlay()
    }
  }, [createdBreakoutSession])

  useEffect(() => {
    setHovered(false)
  }, [width])

  useEffect(() => {
    fetchTemplateModules()
  }, [selectedStage])

  useEffect(() => {
    if (selectedStageName === "Workshop") {
      if (!breakoutGroupsDataFetched) {
        fetchBreakoutGroupsData()
      }
      if (!breakoutGroupsResourcesFetched) {
        fetchBreakoutGroupsResources()
      }
    }
  }, [selectedStageName])

  useEffect(() => {
    if (openInstructions && !breakoutGroupInstructionsFetched) {
      fetchBreakoutGroupsInstructions()
    }
  }, [openInstructions])

  useEffect(() => {
    if (moduleCreated && modules[selectedStage] && modules[selectedStage].modules && modules[selectedStage].modules.length > 0) {
      let module_list = modules[selectedStage].modules
      focusOnModuleName(module_list[module_list.length - 1].id + `_module_input_text`)
    }
  }, [moduleCreated])

  useEffect(() => {
    if (selectedModule != null) {
      fetchCurrentModule()
    }
  }, [selectedModule])

  useEffect(() => {
    fetchModuleResources()
  }, [selectedModule])

  useEffect(() => {
    if (modulesResourcesFetched === false) {
      setModulesResourcesFetched(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (modulesResourcesFetchedx === false) {
      setModulesResourcesFetchedx(true)
    }
  }, [modulesResources[`module_` + selectedModule]])

  useEffect(() => {
    if (currentModuleFetched) {
      fetchCurrentTopics(selectedModule)
    }
  }, [currentModuleFetched])

  useEffect(() => {
    if (hashValue === "showsequencing") {
      setSelectSequecing(true)
    }
    if (isBrowser() && hashValue !== "real") {
      window.location.hash = ""
    }
  }, [hashValue])

  useEffect(() => {
    if (course.course_type && course_template === "") {
      fetchorCreateTemplate()
    }
  }, [course])
  useEffect(() => {
    if (course_template) {
      handleSubmit()
    }
  }, [course.sequence_type])
  useEffect(() => {
    if (selectedTopic != null) {
      fetchTopicResources()
    }
  }, [selectedTopic])

  useEffect(() => {
    if (selectedTopic !== null) {
      fetchSelectedTopic(selectedTopic)
    }
  }, [selectedTopic])

  useEffect(() => {
    if (selectedTopic && topicsResourcesNext[`topic_` + selectedTopic]) {
      fetchNextTopicResources(topicsResourcesNext[`topic_` + selectedTopic], selectedTopic)
    }
  }, [topicsResourcesNext])

  useEffect(() => {
    if (topicCreated === true && selectedModule && modulesData[selectedStage] && modulesData[selectedStage][`module_` + selectedModule]) {
      let datam = modulesData[selectedStage][`module_` + selectedModule].topics
      if (datam && datam.length > 0) {
        setSelectedTopic(datam[datam.length - 1].id)
        setCollapse(false)
        setSelectedTopic(datam[datam.length - 1].id)
      }
    }
  }, [topicCreated])

  useEffect(() => {
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }, [selectedModuleToggle[selectedModule]])

  useEffect(() => {
    setSelectedTopicToggleChange(!selectedModuleToggleChange)
  }, [selectedTopicToggle[selectedTopic]])

  useEffect(() => {
    if (modules[selectedStage] && modules[selectedStage].next_url) {
      fetchNextTemplateModules(modules[selectedStage].next_url)
    }
  }, [modules[selectedStage]])

  async function fetchSelectedTopic(selectedTopic, refetch = false) {
    if (selectedTopic) {
      if ((topicsData && topicsData["topic_" + selectedTopic] === undefined) || refetch) {
        axios
          .get(process.env.GATSBY_REACT_APP_API_HOST + `/topic-basic-detail/${selectedTopic}/`, { headers: login_credentials })
          .then((res) => {
            let current_data = { ...topicsData }
            current_data["topic_" + selectedTopic] = res.data
            setTopicsData(current_data)
            setTopicFetched(true)
          })
          .catch((error) => {
            setTopicFetched(false)
            showErrorMessage(error)
          })
      } else {
        setTopicFetched(true)
      }
    }
  }

  const changeCurrentSession = (topic_id, value) => {
    let data = { ...topicsData }
    data[`topic_${topic_id}`].breakout_sessions = value
    setTopicsData(data)
  }

  function showErrorMessage(error) {
    let error_messages = ""
    for (var key in error.response.data) {
      error_messages += error.response.data[key] + "<br>"
    }
    if (error_messages === "Invalid token.<br>") {
      authClient.signOut()
    }
    document.getElementById("fixed-error-div").innerHTML =
      '<div class="alert alert-danger alert-dismissible fade show" role="alert">' +
      '<button type="button" class="close" aria-label="Close" onClick="javascript:(document.getElementById(\'fixed-error-div\').innerHTML=\'\')">' +
      '<span aria-hidden="true">×</span></button>' +
      error_messages +
      "</div>"
  }

  async function fetchModuleResources() {
    if (selectedModule !== null && modulesResources[`module_` + selectedModule] === undefined) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/resources-names-list/?module=${selectedModule}&paginate_by=10&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          modulesResources[`module_` + selectedModule] = res.data.results
          modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
        })
    }
  }

  async function fetchTopicResources(current_topic = null) {
    let currentTopic = current_topic === null ? selectedTopic : current_topic
    if ((currentTopic !== null && topicsResources[`topic_` + currentTopic] === undefined) || current_topic !== null) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/resources-names-list/?topic=${currentTopic}&paginate_by=15&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          let current_data = { ...topicsResources }
          current_data[`topic_` + currentTopic] = res.data.results
          setTopicsResources(current_data)
          let next_data = { ...topicsResourcesNext }
          next_data[`topic_` + currentTopic] = res.data.next === null ? "" : res.data.next
          setTopicsResourcesNext(next_data)
          setTopicsResourcesFetched(true)
          if (current_topic !== null) {
            setRefetched(!refetched)
          }
          setShowSection("")
          setUploadInTopic(null)
          setUploadInModule(null)
          setUploadDataSequence(null)
        })
    }
  }

  async function fetchNextTopicResources(next_url, current_topic = null) {
    let currentTopic = current_topic === null ? selectedTopic : current_topic
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_data = { ...topicsResources }
      current_data[`topic_` + currentTopic] = topicsResources[`topic_` + currentTopic].concat(res.data.results)
      setTopicsResources(current_data)
      let next_data = { ...topicsResourcesNext }
      next_data[`topic_` + currentTopic] = res.data.next === null ? "" : res.data.next
      setTopicsResourcesNext(next_data)
    })
  }

  function deleteResource(id, push_to_bucket = false, item = {}) {
    let value = ""
    if (hashValue !== "real") {
      value = `?draft_content=true&stage_id=${selectedStage}`
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/resources/" + id + "/" + value, { headers: DefaultHeader })
      .then((res) => {
        setResourceDeleted(true)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  async function fetchNextModuleResources(next_url) {
    setModulesResourcesFetched(false)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      var next_module_resources = modulesResources[`module_` + selectedModule]
      if (res.data.results.length > 0) {
        for (var mrs = 0; mrs <= res.data.results.length - 1; mrs++) {
          next_module_resources.push(res.data.results[mrs])
        }
        modulesResources[`module_` + selectedModule] = next_module_resources
      }
      modulesResourcesNext[`module_` + selectedModule] = res.data.next === null ? {} : { next: res.data.next }
      setModulesResourcesFetched(true)
    })
  }

  async function fetchorCreateTemplate() {
    let template = location.props.location.pathname.indexOf("templates/template-content") > -1 ? `&template_id=${location.template_id}` : ``
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/default-course-templates/fetch_or_create/${location.id}/?draft_content=${hashValue === "real" ? `false` : `true`}${template}`,
        { headers: login_credentials }
      )
      .then((res) => {
        setCourseTemplate(res.data)

        if (res.data.stages.length > 0) {
          if (course.course_type === "1" || course.course_type === "3") {
            setShowOnlyWorkshop(true)
          }
          setSelectedStageName(res.data.stages[0].name)
          setSelectedStage(res.data.stages[0].id)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  const fetchCourse = async () => {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/courses/${location.id}/?draft_content=${hashValue === "real" ? `false` : `true`}`, {
        headers: login_credentials,
      })
      .then(async (res) => {
        if (res.data.duration > 0) {
          setCourseDays(Math.ceil(res.data.duration / 24))
        }
        setCourse(res.data)
        if (!localStorage.getItem(config.accessTokenKey) && res.data?.mural_account) {
          let muralRefreshToken = await refreshToken(res.data?.mural_account)
        }
        let str = res.data.name
        if (str.length > 23) {
          let res1 = str.substring(0, 22)
          let res2 = res1 + "..."
          setCourseName(res2)
        } else {
          setCourseName(str)
        }
        setSequenceType(res.data.sequence_type)
        if (location.props.location.pathname.indexOf("templates/template-content") > -1) {
          setSelectedTemplate(location.template_id)
        } else if (res.data.draft_template_id !== null && hashValue !== "real") {
          setSelectedTemplate(res.data.draft_template_id ? res.data.draft_template_id : null)
        } else {
          setSelectedTemplate(res.data.course_template ? res.data.course_template.id : null)
        }
      })
  }
  async function fetchTemplateModules() {
    if (selectedStage && modules[selectedStage] === undefined) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/basic-modules-list/?stage=${selectedStage}&paginate_by=15&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          let current_modules = { ...modules }
          current_modules[selectedStage] = {}
          current_modules[selectedStage].modules = res.data.results
          current_modules[selectedStage].loader = false
          current_modules[selectedStage].next_url = res.data.next
          current_modules[selectedStage].fetched = true
          if (res.data.next) {
            current_modules[selectedStage].next_loader = true
          }
          setModules(current_modules)
          if (res.data.results.length === 0 && selectedStageName !== "Workshop") {
            setModuleCreated(false)
            createModule(null)
            setCurrentModuleFetched(true)
          }
        })
    }
  }
  async function fetchNextTemplateModules(next_url) {
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_modules = { ...modules }
      current_modules[selectedStage].modules = modules[selectedStage].modules.concat(res.data.results)
      current_modules[selectedStage].next_url = res.data.next
      if (res.data.next) {
        current_modules[selectedStage].next_loader = true
        fetchNextTemplateModules(res.data.next)
      } else {
        current_modules[selectedStage].next_loader = false
      }
      setModules(current_modules)
    })
  }
  async function fetchCurrentModule(s_module = null) {
    let current_module = s_module !== null ? s_module : selectedModule
    if (modulesData[selectedStage] === undefined || (modulesData[selectedStage] && modulesData[selectedStage][`module_` + current_module] === undefined)) {
      setCurrentModuleFetched(false)
      axios
        .get(process.env.GATSBY_REACT_APP_API_HOST + `/module-basic-detail/${current_module}/`, { headers: login_credentials })
        .then((res) => {
          let current_data = { ...modulesData }
          if (current_data[selectedStage] === undefined) {
            current_data[selectedStage] = {}
          }
          current_data[selectedStage][`module_` + current_module] = res.data
          current_data[selectedStage][`module_` + current_module].loader = false
          setModulesData(current_data)
          setCurrentModuleTopicsFetched(false)
          if (s_module === null) {
            setCurrentModuleFetched(true)
          } else {
            setSelectedModule(current_module)
            setCurrentModuleFetched(true)
          }
        })
        .catch((error) => {
          setCurrentModuleFetched(false)
          showErrorMessage(error)
        })
    } else {
      setCurrentModuleTopicsFetched(true)
    }
  }

  async function fetchCurrentTopics(s_module, refetch = false) {
    let stage_data = modulesData[selectedStage]
    let current_module = s_module
    if ((stage_data && stage_data[`module_` + current_module] && stage_data[`module_` + current_module].topics === undefined) || refetch) {
      axios
        .get(
          process.env.GATSBY_REACT_APP_API_HOST +
            `/basic-topics-list/?module=${current_module}&paginate_by=15&draft_content=${hashValue === "real" ? `false` : `true`}`,
          { headers: login_credentials }
        )
        .then((res) => {
          let current_data = { ...modulesData }
          current_data[selectedStage][`module_` + current_module].topics = res.data.results
          current_data[selectedStage][`module_` + current_module].topics_next = res.data.next
          current_data[selectedStage].topics_loader = false
          setSelectedTopic(null)
          setCurrentModuleTopicsFetched(true)
          setModulesData(current_data)
          if (res.data.results.length > 0) {
            let first_topic = res.data.results[0]
            setSelectedTopic(first_topic.id)
            toggleTopic(null, first_topic.id)
          }
          if (res.data.next) {
            fetchNextCurrentTopics(res.data.next)
          }
          if (
            res.data.results.length === 0 &&
            ((modulesData[selectedStage][`module_` + current_module].module_type === "5" && course.course_type === "3") ||
              modulesData[selectedStage][`module_` + current_module].module_type !== "5")
          ) {
            setTopicCreated(false)
            let current_selectedModuleToggle = { ...selectedModuleToggle }
            current_selectedModuleToggle[selectedModule] = true
            setSelectedModuleToggle(current_selectedModuleToggle)
            createTopic(null, selectedModule, null, true, modulesData[selectedStage][`module_` + current_module].module_type)
          }
        })
        .catch((error) => {
          setCurrentModuleTopicsFetched(false)
          showErrorMessage(error)
        })
    } else {
      setSelectedTopic(null)
      setCurrentModuleTopicsFetched(true)
    }
  }
  async function fetchNextCurrentTopics(next_url) {
    let current_data = { ...modulesData }
    current_data[selectedStage][`module_` + selectedModule].topics_next_loader = true
    setModulesData(current_data)
    axios.get(next_url.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      let current_data = { ...modulesData }
      current_data[selectedStage][`module_` + selectedModule].topics = current_data[selectedStage][`module_` + selectedModule].topics.concat(res.data.results)
      current_data[selectedStage][`module_` + selectedModule].topics_next = res.data.next
      current_data[selectedStage][`module_` + selectedModule].topics_next_loader = false
      setModulesData(current_data)
      setCurrentModuleTopicsFetched(true)
    })
  }
  function focusOnModuleName(id) {
    let data = document.getElementById(id)
    if (data) {
      data.click()
    }
  }

  function handleSubmit(event = null, create_new_session = false) {
    if (event) {
      event.preventDefault()
    }
    var formData = new FormData()
    for (var k in course) {
      if (course[k] !== null) {
        if (k === "course_template") {
          if (course.course_template !== null) {
            formData.append("course_template", JSON.stringify(course.course_template))
          }
        } else if (k === "cohort_schedule") {
          formData.append(k, JSON.stringify(course[k]))
        } else if (k !== "avatar") {
          formData.append(k, course[k])
        }
      }
    }
    if (location.id !== undefined) {
      var company_id = location.id
      axios
        .put(process.env.GATSBY_REACT_APP_API_HOST + "/courses/" + company_id + "/", formData, { headers: login_credentials })
        .then((res) => {
          if (create_new_session && course.course_type === "3") {
            let session_count = course.cohort_schedule.session_count
            let all_modules = modules[selectedStage].modules
            let session_module_count = all_modules.filter((session) => session.module_type === "3").length
            if (session_module_count < session_count) {
              setModuleCreated(false)
              createModule(event)
            }
          }
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }
  function handleSave(name, value, datum, type) {
    setModuleEdited(false)
    if (datum?.owner?.id) {
      datum.owner = datum?.owner?.id
    }
    if (datum.id) {
      datum[name] = value
      var formData = new FormData()
      for (var k in datum) {
        if (datum[k] !== null && k !== "suggested_by") {
          if (k === "start_date" || k === "end_date") {
            if (datum[k]) {
              formData.append(k, new Date(datum[k]).toISOString())
            }
          } else if (k === "activity_workspace") {
            formData.append("activity_workspace", JSON.stringify(datum[k]))
          } else {
            formData.append(k, datum[k])
          }
        }
      }
      // for drafting
      if (type === "module" && hashValue !== "real") {
        formData.append(`stage_id`, selectedStage)
      } else if (type === "topic" && hashValue !== "real") {
        formData.append(`stage_id`, selectedStage)
      } else if (type === "resource" && hashValue !== "real") {
        formData.append(`stage_id`, selectedStage)
      }
      // for end
      if (type) {
        axios
          .put(process.env.GATSBY_REACT_APP_API_HOST + `/${type}s/` + datum.id + `/`, formData, { headers: DefaultHeader })
          .then((res) => {
            setModuleEdited(true)
          })
          .catch((error) => {
            showErrorMessage(error)
          })
      }
    } else {
    }
  }
  function selectModuleName(type) {
    if (type === "5") {
      if (course.course_type === "1") {
        return "Break"
      } else {
        return "Module"
      }
    } else if (selectedStageName === "Workshop" && course.course_type !== "1") {
      return `Session `
    } else {
      return `Module `
    }
  }
  function calculateModuleCount(modules) {
    if (selectedStageName === "Workshop") {
      if (course.course_type === "3" || course.course_type === "0") {
        if (modules.length > 0) {
          return modules.filter((module) => module.module_type === "3").length
        } else return 0
      } else {
        return modules.length
      }
    } else {
      return modules.length
    }
  }

  function getTimeDifference(start_date, end_date, type) {
    let s_hours = utcDateTime.utcDateTime(start_date, "hour")
    let e_hours = utcDateTime.utcDateTime(end_date, "hour")
    let s_min = utcDateTime.utcDateTime(start_date, "minute")
    let e_min = utcDateTime.utcDateTime(end_date, "minute")
    let hour_diff = s_hours > e_hours ? e_hours - s_hours + 24 : e_hours - s_hours
    let min_diff = s_min > e_min ? e_min - s_min + 60 : e_min - s_min
    if (type === "hour") {
      return hour_diff
    } else {
      return min_diff
    }
  }

  function suggestDayForNextSession() {
    let allSessions = modules[selectedStage].modules.filter((module) => module.module_type === "3")
    let lastSession = allSessions[allSessions.length - 1]
    let lastSesionDay = lastSession.course_day
    let totalHoursPerDay = 0
    let totalMin = 0
    for (let i = 0; i < allSessions.length; i++) {
      if (allSessions[i].course_day === lastSesionDay) {
        totalHoursPerDay = totalHoursPerDay + getTimeDifference(allSessions[i].start_date, allSessions[i].end_date, "hour")
        totalMin = totalMin + getTimeDifference(allSessions[i].start_date, allSessions[i].end_date, "min")
      }
    }
    if (totalMin >= 60) {
      totalHoursPerDay = totalHoursPerDay + (totalMin % 60)
      totalMin = totalMin / 60
    }
    if (totalHoursPerDay >= 8 && lastSesionDay <= courseDays) {
      setShowSuggestDayModal(true)
      setRecentDay(lastSesionDay)
      return true
    } else {
      return false
    }
  }

  function setSuggestedDay(e, suggestedDay, type = "", index_for_sequence = -1) {
    let module_length = modules[selectedStage] ? modules[selectedStage].modules : []
    let sequence = (calculateModuleCount(module_length) + 1) * 10
    let final_sequence = (1 * 10).toFixed(20)
    if (index_for_sequence > -1) {
      let second_sequence = parseFloat(module_length[index_for_sequence].sequence)
      if (module_length[index_for_sequence - 1]) {
        let first_sequence = parseFloat(module_length[index_for_sequence - 1].sequence)
        final_sequence = ((first_sequence + second_sequence) / 2).toFixed(20)
      } else {
        final_sequence = (second_sequence - 10).toFixed(20)
      }
    } else if (module_length.length > 0) {
      final_sequence = (parseFloat(module_length[module_length.length - 1].sequence) + 10).toFixed(20)
    }
    let module = {}
    module.name = selectModuleName(type)
    module.name = type ? module.name : module.name + ` ${sequence / 10}`
    module.sequence = final_sequence
    module.add_to_stage = selectedStage
    module.published = true
    module.module_type = type ? type : selectedStageName === "Workshop" ? "3" : "0"
    if (selectedStageName === "Workshop" && course.course_type === "0") {
      module.course_day = suggestedDay
    }
    var formData = new FormData()
    if (hashValue !== "real") {
      formData.append("draft_content", "true")
    }
    for (var k in module) {
      formData.append(k, module[k])
    }
    if (module.id !== undefined) {
    } else {
      setModuleCreated(false)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/modules/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            new_data.published = res.data.published
            new_data.duration = res.data.duration
            new_data.reminder_time = res.data.reminder_time
            new_data.recurring_reminder = res.data.recurring_reminder
            new_data.start_date = res.data.start_date
            new_data.breakout_session_distribution_type = res.data.breakout_session_distribution_type
            new_data.topic_count = res.data.topics.length
            new_data.module_type = res.data.module_type
            new_data.course_day = res.data.course_day
            let current_modules = { ...modules }
            if (current_modules[selectedStage] === undefined) {
              current_modules[selectedStage] = {}
              current_modules[selectedStage].modules = [new_data]
              current_modules[selectedStage].loader = false
              current_modules[selectedStage].next_url = null
              current_modules[selectedStage].next_loader = false
            } else {
              if (index_for_sequence > -1) {
                let new_index = index_for_sequence - 1 > -1 ? index_for_sequence - 1 : 0
                if (new_index === -1) {
                  current_modules[selectedStage].modules.unshift(new_data)
                } else {
                  modules[selectedStage].modules.splice(index_for_sequence, 0, new_data)
                }
              } else {
                current_modules[selectedStage].modules = modules[selectedStage].modules.concat(new_data)
              }
            }
            setModules(current_modules)
            setSelectedModule(res.data.id)
            if ((res.data.module_type === "5" && course.course_type === "3") || res.data.module_type !== "5") {
              toggleModule(e, res.data.id)
            }
            scrollToSpecific(e, "modules_" + res.data.id, "module")
            setModuleCreated(true)
            setShowSuggestDayModal(false)
          }
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }

  function createModule(e, type = "", index_for_sequence = -1) {
    if (e) {
      e.preventDefault()
    }
    let flag = false
    if (modules[selectedStage] && modules[selectedStage].modules.length > 0 && selectedStageName === "Workshop" && course.course_type == "0" && type !== "5") {
      flag = suggestDayForNextSession()
    }

    if (flag) {
    } else {
      let lastSesionDay = 1
      if (modules[selectedStage] && modules[selectedStage].modules.length > 0 && selectedStageName === "Workshop" && course.course_type == "0") {
        let allSessions = modules[selectedStage].modules
        let lastSession = allSessions[allSessions.length - 1]
        lastSesionDay = lastSession.course_day
      } else {
        lastSesionDay = 1
      }

      let module_length = modules[selectedStage] ? modules[selectedStage].modules : []
      let sequence = (calculateModuleCount(module_length) + 1) * 10
      let final_sequence = (1 * 10).toFixed(20)
      if (index_for_sequence > -1) {
        let second_sequence = parseFloat(module_length[index_for_sequence].sequence)
        if (module_length[index_for_sequence - 1]) {
          let first_sequence = parseFloat(module_length[index_for_sequence - 1].sequence)
          final_sequence = ((first_sequence + second_sequence) / 2).toFixed(20)
        } else {
          final_sequence = (second_sequence - 10).toFixed(20)
        }
      } else if (module_length.length > 0) {
        final_sequence = (parseFloat(module_length[module_length.length - 1].sequence) + 10).toFixed(20)
      }
      let module = {}
      module.name = selectModuleName(type)
      module.name = type ? module.name : module.name + ` ${sequence / 10}`
      module.sequence = final_sequence
      module.add_to_stage = selectedStage
      module.published = true
      module.module_type = type ? type : selectedStageName === "Workshop" ? "3" : "0"
      if (selectedStageName === "Workshop" && course.course_type === "0") {
        module.course_day = lastSesionDay
      }
      var formData = new FormData()
      if (hashValue !== "real") {
        formData.append("draft_content", "true")
      }
      for (var k in module) {
        formData.append(k, module[k])
      }
      if (module.id !== undefined) {
      } else {
        setModuleCreated(false)
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/modules/create/", formData, { headers: login_credentials })
          .then((res) => {
            let new_data = {}
            if (res.data) {
              new_data.id = res.data.id
              new_data.name = res.data.name
              new_data.sequence = res.data.sequence
              new_data.published = res.data.published
              new_data.duration = res.data.duration
              new_data.reminder_time = res.data.reminder_time
              new_data.recurring_reminder = res.data.recurring_reminder
              new_data.start_date = res.data.start_date
              new_data.breakout_session_distribution_type = res.data.breakout_session_distribution_type
              new_data.topic_count = res.data.topics.length
              new_data.module_type = res.data.module_type
              new_data.course_day = res.data.course_day
              let current_modules = { ...modules }
              if (current_modules[selectedStage] === undefined) {
                current_modules[selectedStage] = {}
                current_modules[selectedStage].modules = [new_data]
                current_modules[selectedStage].loader = false
                current_modules[selectedStage].next_url = null
                current_modules[selectedStage].next_loader = false
              } else {
                if (index_for_sequence > -1) {
                  let new_index = index_for_sequence - 1 > -1 ? index_for_sequence - 1 : 0
                  if (new_index === -1) {
                    current_modules[selectedStage].modules.unshift(new_data)
                  } else {
                    modules[selectedStage].modules.splice(index_for_sequence, 0, new_data)
                  }
                } else {
                  current_modules[selectedStage].modules = modules[selectedStage].modules.concat(new_data)
                }
              }
              setModules(current_modules)
              setSelectedModule(res.data.id)
              if ((res.data.module_type === "5" && course.course_type === "3") || res.data.module_type !== "5") {
                toggleModule(e, res.data.id)
              }
              scrollToSpecific(e, "modules_" + res.data.id, "module")
              setModuleCreated(true)
            }
          })
          .catch((error) => {
            showErrorMessage(error)
          })
      }
    }
  }
  function scrollToSpecific(e, value, type, topic_id = "") {
    if (e !== null) {
      e.preventDefault()
    }
    if (isBrowser() && value !== "" && document.getElementById(value) && document.getElementById("scrollable-div")) {
      let scroll_value = 0
      if (type === "module") {
        scroll_value = document.getElementById("scrollable-div").scrollHeight
      } else {
        let topic_div = document.getElementById(topic_id).getBoundingClientRect()
        let current_scroll = document.getElementById("scrollable-div").scrollTop
        scroll_value = current_scroll + topic_div.top - 150
      }
      setTimeout(function () {
        document.getElementById("scrollable-div").scroll({
          behavior: "smooth",
          left: 0,
          top: scroll_value,
        })
      }, 200)
    }
  }
  function createTopic(e, module_id, draggedItem = null, from_auto_create = false, module_type = "") {
    let topic = {}
    if (e) {
      e.preventDefault()
    } else {
      topic.visible_as_resource = true
    }
    let topics = modulesData[selectedStage][`module_` + module_id].topics
    let sequence = (topics.length + 1) * 10
    let final_sequence = (1 * 10).toFixed(20)
    if (topics.length > 0) {
      final_sequence = (parseFloat(topics[topics.length - 1].sequence) + 10).toFixed(20)
    }
    topic.name = course.course_type === "3" && selectedStageName === "Workshop" && module_type !== "5" ? "Lesson " : "Topic " + (topics.length + 1)
    topic.sequence = final_sequence
    topic.add_to_module = module_id
    topic.published = true
    var formData = new FormData()
    for (var k in topic) {
      formData.append(k, topic[k])
    }
    if (hashValue !== "real") {
      formData.append("draft_content", "true")
      formData.append(`stage_id`, selectedStage)
    }
    if (topic.id !== undefined) {
    } else {
      setTopicCreated(false)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + "/topics/create/", formData, { headers: login_credentials })
        .then((res) => {
          let new_data = {}
          if (res.data) {
            new_data.id = res.data.id
            new_data.name = res.data.name
            new_data.sequence = res.data.sequence
            new_data.visible_as_resource = res.data.visible_as_resource
            new_data.published = res.data.published
            new_data.weightage = res.data.weightage
            new_data.duration = res.data.duration
            let current_data = { ...modulesData }
            current_data[selectedStage][`module_` + module_id].topics = current_data[selectedStage][`module_` + module_id].topics.concat(new_data)
            setModulesData(current_data)
            let current_modules = { ...modules }
            let selected_module = current_modules[selectedStage].modules.filter((module) => module.id === module_id)[0]
            selected_module["topic_count"] = selected_module.topic_count + 1
            let module_index = current_modules[selectedStage].modules.indexOf(selected_module)
            current_modules[module_index] = selectedModule
            setModules(current_modules)
            toggleTopic(e, res.data.id)
            setCurrentModuleTopicsFetched(true)
            setTopicCreated(true)
            if (!from_auto_create) {
              scrollToSpecific(e, "modules_" + module_id, "topic", "topics_" + res.data.id)
            }
            if (e === null && !from_auto_create) {
              setVisibleTopicData({})
              setVisibleTopicDataFetched(!visibleTopicDataFetched)
            }
          }
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }
  function toggle(e, type) {
    e.preventDefault()
  }
  function toggleTopic(e, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedTopicToggle[datum_id] = !selectedTopicToggle[datum_id]
    setSelectedTopicToggleChange(!selectedTopicToggleChange)
  }

  async function deleteModule() {
    let url = selectedDeletingModule && selectedDeletingModule.id ? `modules` : `topics`
    if (url === "modules") {
      var current_id = selectedDeletingModule.id
      var index = modules[selectedStage].modules.findIndex((res) => res.id === current_id)
    } else {
      var current_id = selectedDeletingTopic.id
      var index = modulesData[selectedStage][`module_` + selectedModule].topics.findIndex((res) => res.id === current_id)
    }
    let value = ""
    if (hashValue !== "real") {
      value = `&draft_content=true&stage_id=${selectedStage}`
    }
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/${url}/` + current_id + "/?delete_children=true" + value, {
        headers: DefaultHeader,
        body: url === "modules" ? selectedDeletingModule : selectedDeletingTopic,
      })
      .then((res) => {
        if (url === "modules") {
          let current_modules = modules[selectedStage].modules
          current_modules.splice(index, 1)
        } else {
          let item
          if (deleteVisibleResource && deleteVisibleResource.length > 0) {
            item = deleteVisibleResource[0]
            item.id = item.resource_data.id
            item.thumbnail_url = item.resource_data.thumbnail_url
            item.name = item.resource_data?.display_name ?? item.resource_data?.name
            let type = item["resource_type"]
            delete item["resource_data"]
            delete item["resource_id"]
            delete item["resource_type"]
          }
          modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics.splice(index, 1)
          if (
            modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics &&
            modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics.length > 0
          ) {
            setTopicFetched(false)
            setSelectedTopic(modulesData[selectedStage][`module_` + selectedDeletingTopic.module].topics[0].id)
          } else {
            setSelectedTopic(null)
          }
        }
        setSelectedDeletingModule([])
        setSelectedDeletingTopic([])
        setDeleteVisibleResource([])
        setDmodal(!dmodal)
        setShowWorkshopInfo(false)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  function toggleModule(e = null, datum_id) {
    if (e) {
      e.preventDefault()
    }
    selectedModuleToggle[datum_id] = !selectedModuleToggle[datum_id]
    setSelectedModuleToggleChange(!selectedModuleToggleChange)
  }
  function fileInputClicked(event, id, module_id, data) {
    setUploadInTopic(id)
    setUploadInModule(module_id)
    setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))
    window.removeEventListener("focus", handleFocusBack)
  }

  function handleFocusBack() {
    setUploadInTopic(null)
    setUploadInModule(null)
    setUploadDataSequence(null)
    window.removeEventListener("focus", handleFocusBack)
  }

  function clickedFileInput() {
    window.addEventListener("focus", handleFocusBack)
  }

  function getImageToBeRendered(item) {
    let imgObj = turientimage,
      typeId = 2

    let typeOfFile = item?.resource_data?.file_type || 0
    if (item?.resource_data?.avatar) {
      imgObj = item.resource_data.avatar
    }

    if (item.resource_type === "video") {
      imgObj = video
    } else if (item.resource_type === "blog") {
      imgObj = blog
    } else if (item.resource_type === "link") {
      imgObj = link
    } else if (item.resource_type === "quiz") {
      imgObj = quiz
      let dataType = item?.resource_data?.data_type || 0
      if (dataType === "1") {
        imgObj = poll
      } else if (dataType === "2") {
        imgObj = popQuiz
      }
    } else if (item.resource_type === "turientimage") {
      imgObj = faImage
      typeId = 1
      if (typeOfFile === "2" || typeOfFile === "5") {
        imgObj = doc
        typeId = 2
      } else if (typeOfFile === "3" || typeOfFile === "7") {
        imgObj = excel
        typeId = 2
      } else if (typeOfFile === "4" || typeOfFile === "6") {
        imgObj = ppt
        typeId = 2
      } else if (typeOfFile === "1") {
        imgObj = pdf
        typeId = 2
      }
    } else if (item.resource_type === "checklist") {
      imgObj = checklist
    } else if (item.resource_type === "casestudy") {
      imgObj = casestudy
    } else if (item.resource_type === "matchthefollowing") {
      imgObj = whiteMatchingIcon
    }
    return { typeId, imgObj }
  }

  function showResourcesButton(type, id, visible_as_resource = false, module_id = null) {
    let data = []
    let stage_data = modulesData[selectedStage]
    if (type === `topic`) {
      var index = null
      if (
        modulesData[selectedStage][`module_` + module_id] &&
        modulesData[selectedStage][`module_` + module_id].topics &&
        modulesData[selectedStage][`module_` + module_id].topics.length > 0
      ) {
        index = modulesData[selectedStage][`module_` + module_id].topics.findIndex((res) => res.id === id)
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + id]
          : index !== null && modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          ? modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          : topicsResources[`topic_` + id]
    } else if (type === `module`) {
      data = modulesResources[`module_` + id]
    }

    let next_url = null
    let see_more_loader = false

    if (type === "topic") {
      next_url = topicsResourcesNext[`topic_` + id]
      see_more_loader = topicsResourcesNextLoader[`topic_` + id]
    } else {
      next_url = modulesResourcesNext[`module_` + id]
    }
    if (data) {
      return (
        <Droppable
          isDropDisabled={createBreakoutSessionOpened || showCommonItemsModal || !isPermitted}
          droppableId={`${module_id === null ? `` : module_id + `_`}${type}_${id}`}
          type={`resourceDraggable`}
        >
          {(provided, snapshot) => (
            <div
              id={`drop-area`}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={(e) => onDrop(e, module_id, id, data)}
              className={`bg-transparent px-4 ${type} ${!isPermitted && "disable-content"} ${type === `resource` ? `resources` : ``} ${
                type === `resource` && collapse ? `${Styles.opacity_0} p-0` : `${Styles.opacity_1}`
              }`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((item, index) => {
                let { typeId, imgObj } = getImageToBeRendered(item)
                return (
                  <>
                    <Draggable
                      key={item.id + `_${type}_name_` + index}
                      draggableId={item.id.toString() + `-${type}` + "_" + index.toString()}
                      index={index}
                      className={item.resource_data !== null ? "" : "d-none"}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            className={`pr-2 d-flex`}
                            style={{
                              color: `#E0E2E5`,
                              background: "#4F6080",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex" style={{ alignItems: "center" }}>
                              <FontAwesomeIcon
                                className={`${modulesResourcesFetched} ${topicsResourcesFetched} ml-1 ${item.resource_type ? `d-none` : ``}`}
                                icon={faSpinner}
                                size="lg"
                              />
                              {item.resource_data ? (
                                item.resource_data.thumbnail_url ? (
                                  <LazyLoadImage
                                    className={`my-auto`}
                                    src={item.resource_data.thumbnail_url}
                                    alt={item.name}
                                    style={{
                                      width: `28px`,
                                      height: `20px`,
                                      fontSize: `12px`,
                                    }}
                                  />
                                ) : (
                                  <>
                                    {typeId === 1 ? (
                                      <FontAwesomeIcon
                                        icon={imgObj}
                                        className={`my-0`}
                                        style={{
                                          color: "#67edfd",
                                          marginBottom: 0,
                                          width: `28px`,
                                          height: `20px`,
                                        }}
                                      />
                                    ) : (
                                      <LazyLoadImage
                                        className={`my-auto`}
                                        src={imgObj}
                                        alt={item.name}
                                        style={{
                                          width: `28px`,
                                          height: `20px`,
                                          fontSize: `12px`,
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              ) : (
                                ``
                              )}
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 10px",
                                }}
                              >
                                {item.resource_data ? item.resource_data?.display_name ?? item.resource_data?.name : item.name}
                              </p>
                            </div>
                            <div>
                              {isPermitted && (
                                <img
                                  src={DeleteIcon}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    const newState = data
                                    newState.splice(index, 1)
                                    setResourceDeleted(false)
                                    if (type === "topic") {
                                      topicsResources[`topic_` + id] = newState
                                      setTopicsResourcesFetched(false)
                                    } else {
                                      modulesResources[`module_` + id] = newState
                                      setModulesResourcesFetched(false)
                                    }
                                    deleteResource(item.id, true, item)
                                  }}
                                  alt={"resource delete icon"}
                                  className={`my-auto pointer`}
                                  style={{ objectFit: `contain`, height: `13px` }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                )
              })}
              {provided.placeholder}
              <React.Fragment>
                {type === "module" && next_url && Object.keys(next_url).length > 0 ? (
                  <div className={`text-center`}>
                    <Button
                      variant="none"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        fetchNextModuleResources(next_url.next)
                      }}
                      className={`${Styles.font_12} border_on_hover text-center px-3 py-1 mt-2`}
                      style={{
                        backgroundColor: `#212c42`,
                        color: `#c6cff4`,
                        borderRadius: `15px`,
                      }}
                    >
                      See More
                    </Button>
                  </div>
                ) : type === "topic" && topicsResourcesNext[`topic_` + id] ? (
                  <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                    <Spinner animation="border" variant="secondary" size="sm" />
                  </Col>
                ) : (
                  ""
                )}
                <div className={`justify-content-center pt-4 pb-3 ${type === "resource" ? `d-none` : `d-flex bg-transparent`}`}>
                  {/*<p className={`pl-3`} >
                    <span className={`${Styles.font_14}`} style={{ color: `#E0E2E5` }}>Drag and drop media/document files to add content</span>
                  </p>*/}
                  <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                    <div className={`w-100 col-12`}>
                      <Form className="my-form mb-0">
                        <div>
                          {/* onClick={(e)=>{setUploadInTopic(id);setUploadInModule(module_id);setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))}} >*/}
                          {isPermitted && (
                            <p className={`${StylesM.font_14} mb-2`}>
                              Drag and drop media/document files to add content
                              {/*/&nbsp;<label className={`button btn border-0 px-0 ${StylesM.font_14}`} style={{ color:"#E0E2E5",textDecoration:'underline'}} htmlFor="fileElem">Browse</label>*/}
                            </p>
                          )}
                          {/*<p className={`${StylesM.font_14} mb-4`} >Files Supported: MP4/ PDF/ XLS/ JPG/PNG</p>*/}
                        </div>
                        {files.length === 0 ? (
                          <input
                            className={`d-none`}
                            type="file"
                            id={"fileElem_" + id}
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onClick={(e) => {
                              clickedFileInput(e)
                            }}
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              fileInputClicked(e, id, module_id, data)
                              validateAndAdd(e)
                            }}
                            disabled={!isPermitted}
                          />
                        ) : null}
                      </Form>
                    </div>
                    {uploadInModule !== null &&
                    uploadInTopic !== null &&
                    uploadDataSequence !== null &&
                    uploadInModule === module_id &&
                    uploadInTopic === id ? (
                      <UploadFiles
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchTopicResources(id)}
                        showSection={showSection}
                        setShowSection={setShowSection}
                        refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                        properties={{
                          module: modules[selectedStage].modules[modules[selectedStage].modules.findIndex((res) => res.id === uploadInModule)].name,
                          topic: uploadInTopic,
                          mainId: uploadInTopic,
                          itemName:
                            stage_data[`module_` + uploadInModule].topics[
                              stage_data[`module_` + uploadInModule].topics.findIndex((res) => res.id === uploadInTopic)
                            ].name,
                          sequence: uploadDataSequence,
                          draft_content: hashValue === "real" ? `false` : `true`,
                          stage_id: selectedStage,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function openNavWithResourceSection(section, type) {
    if (!leftNavOpen) {
      setLeftNavOpen(true)
    }
    if (activeNavItem !== section) {
      setActiveNavItem(section)
    }
    if (activeResourceType !== type) {
      setActiveResourceType(type)
    }
  }

  function uploadDataProps(topic_id, module_id, data, showSection = "") {
    let seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)

    setUploadInTopic(topic_id)
    setUploadInModule(module_id)
    setUploadDataSequence(seq)
    setShowSection(showSection)

    if (addResourceType === "common") {
      setAttrForResources({
        template: topic_id,
        mainId: topic_id,
        add_to_group_resource: "true",
        draft_content: hashValue === "real" ? `false` : `true`,
        stage_id: selectedStage,
      })
    } else {
      setAttrForResources({
        topic: topic_id,
        mainId: topic_id,
        draft_content: hashValue === "real" ? `false` : `true`,
        stage_id: selectedStage,
      })
    }
  }
  function uploadDocs(e, topic_id, module_id, data, upload, showSection) {
    e.preventDefault()
    e.stopPropagation()
    uploadDataProps(topic_id, module_id, data, showSection)
    let domObj = document.getElementById("fileElem_" + topic_id)
    if (domObj && upload) {
      if (showSection === "turientimage") {
        domObj.accept = "image/*"
      }
      domObj.click()
    }
  }
  function openNewWin(section, type, mainId, module_id, data) {
    let wnd,
      seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)
    uploadDataProps(mainId, module_id, data)
    let draft_content = hashValue === "real" ? `false` : `true`
    let stage_id = selectedStage

    if (type === "quiz") {
      wnd = window.open("/app/quizzes/create", type + "12")
      quizWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId: course.course_template.id,
      }
    } else if (type === "mtf") {
      wnd = window.open("/app/match-the-following/create", type + "12")
      mtfWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId: course.course_template.id,
      }
    } else if (type === "casestudy") {
      wnd = window.open("/app/case-study/create", type + "12")
      casestudyWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId: course.course_template.id,
      }
    } else if (type === "checklist") {
      wnd = window.open("/app/checklist/create", type + "12")
      checklistWin.current = {
        wnd,
        mainId,
        module_id,
        seq,
        section,
        draft_content,
        stage_id,
        addResourceType,
        parentTempId: course.course_template.id,
      }
    }
  }
  function getNewWinData(type) {
    let win = null
    if (type === "quiz") {
      win = quizWin.current
    } else if (type === "mtf") {
      win = mtfWin.current
    } else if (type === "casestudy") {
      win = casestudyWin.current
    } else if (type === "checklist") {
      win = checklistWin.current
    }
    return win
  }

  function handleNewWinMessages(event) {
    if (event.origin !== window.location.origin) return

    if (event.data && event.data.instruction) {
      if (event.data.instruction === "ready") {
        let win = getNewWinData(event.data.type)
        if (win && win.wnd) {
          let obj = {
            topic: win.mainId,
            sequence: win.seq,
            draft_content: win.draft_content,
            stage_id: win.stage_id,
          }
          if (win.addResourceType === "common") {
            obj = {
              template: win.mainId,
              sequence: win.seq,
              add_to_group_resource: "true",
              draft_content: win.draft_content,
              stage_id: win.stage_id,
            }
          }

          win.wnd.postMessage({ instruction: { ...obj } }, window.location.origin)
        }
      } else if (event.data.instruction === "payload") {
        let win = getNewWinData(event.data.type)
        if (win) {
          setTimeout(() => {
            if (win.addResourceType === "common") {
              fetchBreakoutGroupsResources(win.mainId, win.parentTempId, win.draft_content)
            } else {
              fetchTopicResources(win.mainId)
            }
          }, 1500) //Delay because the python backend thread takes time
          const evt = new CustomEvent("my-refresh-resources", {
            bubbles: true,
            detail: event.data.type,
          })
          document.dispatchEvent(evt)
        }
      }
    }
  }

  function openModal(type, mainId, module_id, data) {
    let seq = data.length === 0 ? 10 : parseFloat(data[data.length - 1].sequence + 10).toFixed(2)
    uploadDataProps(mainId, module_id, data)
    let draft_content = hashValue === "real" ? `false` : `true`
    let stage_id = selectedStage

    let obj = {
      topic: mainId,
      mainId,
      sequence: seq,
      draft_content,
      stage_id,
      addResourceType,
      parentTempId: course.course_template.id,
    }
    if (addResourceType === "common") {
      obj = {
        template: mainId,
        mainId,
        sequence: seq,
        add_to_group_resource: "true",
        draft_content,
        stage_id,
        addResourceType,
        parentTempId: course.course_template.id,
      }
    }

    if (type === "poll") {
      setShowPollModal(true)

      const evt = new CustomEvent("my-poll-data", {
        bubbles: true,
        detail: { ...obj },
      })
      document.dispatchEvent(evt)
    } else if (type === "pop-quiz") {
      setShowPopQuizModal(true)

      const evt = new CustomEvent("my-pop-quiz-data", {
        bubbles: true,
        detail: { ...obj },
      })
      document.dispatchEvent(evt)
    }
  }
  function handlePollModalMsgs(evt) {
    setShowPollModal(false)
    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        fetchBreakoutGroupsResources(evt.detail.mainId, evt.detail.parentTempId, evt.detail.draft_content)
      } else {
        fetchTopicResources(evt.detail.mainId ?? uploadInTopic)
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  function handlePopQuizModalMsgs(evt) {
    setShowPopQuizModal(false)
    setTimeout(() => {
      if (evt.detail.addResourceType === "common") {
        fetchBreakoutGroupsResources(evt.detail.mainId, evt.detail.parentTempId, evt.detail.draft_content)
      } else {
        fetchTopicResources(evt.detail.mainId ?? uploadInTopic)
      }
    }, 1500) //Delay because the python backend thread takes time
  }

  function showTopicsData(datum, visible_as_resource = false, module_id = null, topic_module = null) {
    let topic_id = datum.id
    if (topic_id !== null) {
      // for adding multimedia links start
      let data = []
      let stage_data = modulesData[selectedStage]
      var index = null
      if (
        modulesData[selectedStage][`module_` + module_id] &&
        modulesData[selectedStage][`module_` + module_id].topics &&
        modulesData[selectedStage][`module_` + module_id].topics.length > 0
      ) {
        index = modulesData[selectedStage][`module_` + module_id].topics.findIndex((res) => res.id === topic_id)
      }
      data =
        visible_as_resource === false
          ? topicsResources[`topic_` + topic_id]
          : index !== null && modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          ? modulesData[selectedStage][`module_` + module_id].topics[index].visible_resources
          : topicsResources[`topic_` + topic_id]
      // for adding multimedia links end
      if (visible_as_resource) {
        return showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)
      } else if (topicFetched && topicsData["topic_" + topic_id]) {
        return (
          <div
            style={{
              padding: "12px",
              backgroundColor: topic_module.module_type === "5" ? "#2c3547" : "#36435D",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            {selectedStageName === "Workshop" && course.course_type !== "1" ? (
              <div className="d-flex" style={{ paddingBottom: "12px" }}>
                <DurationInputField topic_module={topic_module} datum={datum} handleSave={handleSave} course={course} selectedStageName={selectedStageName} />
                <WeightageField topic_module={topic_module} datum={datum} handleSave={handleSave} course={course} selectedStageName={selectedStageName} />
              </div>
            ) : (
              ""
            )}
            <div
              key={"div2" + topic_id}
              className={`px-0`}
              style={{
                backgroundColor: topic_module.module_type === "5" ? "#384257" : "#42506C",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                style={{
                  borderRadius: "8px 8px 0px 0px",
                  borderBottom: `1px dashed #838b9e`,
                  padding: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    color: "#E0E2E5",
                    lineHeight: 1,
                    opacity: 0.6,
                  }}
                >
                  Resources
                </p>
              </div>
              {showResourcesButton(`topic`, topic_id, visible_as_resource, module_id)}

              {isPermitted && (
                <div
                  className={`d-flex p-1 ${!isPermitted && "disable-content"}`}
                  style={{
                    borderTop: `1px dashed #838b9e`,
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faVideo}
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "video")
                        uploadDocs(e, topic_id, module_id, data, false, "video")
                      }}
                      title={`Add Videos`}
                      alt={`Add Videos`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                    <img
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "blog")
                        uploadDataProps(topic_id, module_id, data, "")
                        setAddBlog(true)
                      }}
                      src={WhiteBlogIcon}
                      title={`Add Blog`}
                      alt={`add blog button`}
                      className={`${
                        leftNavOpen && activeResourceType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                    <img
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "document")
                        uploadDocs(e, topic_id, module_id, data, false, "document")
                      }}
                      src={documents}
                      alt={`Add Documents`}
                      title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faImage}
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "turientimage")
                        uploadDocs(e, topic_id, module_id, data, true, "turientimage")
                      }}
                      title={`Add Images`}
                      alt={`Add Images`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                    <FontAwesomeIcon
                      icon={faLink}
                      onClick={(e) => {
                        openNavWithResourceSection("Resource", "link")
                        uploadDocs(e, topic_id, module_id, data, false, "link")
                      }}
                      title={`Add Links`}
                      alt={`Add Links`}
                      className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add quiz ({selectedStageName})</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "quiz")
                        openNewWin("Poll", "quiz", topic_id, module_id, data)
                      }}
                      src={WhiteQuizIcon}
                      title={`Add Quiz (${selectedStageName})`}
                      alt={`add quiz button`}
                      className={`${
                        leftNavOpen && activeResourceType === "quiz" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <span style={{ width: 17, height: 17, marginBottom: 12 }}>&nbsp;|&nbsp;</span>
                  {/*<img onClick={(e)=>{e.stopPropagation();openNavWithResourceSection('Resource','turientimage')}} src={WhiteTurientimageIcon} alt={`add file button`} className={`${leftNavOpen && activeResourceType === 'turientimage' ? StylesB.resource_select_icon_active : StylesB.resource_select_icon} my-auto mx-1 pointer`} style={{ objectFit: `contain`, width: `17px`, height: `17px`, color: '#fff' }}/>*/}
                  <OverlayTrigger overlay={<Tooltip>Add poll</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "poll")
                        openModal("poll", topic_id, module_id, data)
                      }}
                      src={WhitePollIcon}
                      title={`Add Poll`}
                      alt={`add poll button`}
                      className={`${
                        leftNavOpen && (activeResourceType === "poll" || activeResourceType === "poll")
                          ? StylesB.resource_select_icon_active
                          : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  {selectedStageName === "Workshop" ? (
                    <OverlayTrigger overlay={<Tooltip>Add pop-quiz</Tooltip>}>
                      <img
                        onClick={(e) => {
                          e.stopPropagation()
                          openNavWithResourceSection("Poll", "pop-quiz")
                          openModal("pop-quiz", topic_id, module_id, data)
                        }}
                        src={popQuiz}
                        title={`Add Pop-Quiz`}
                        alt={`add pop quiz button`}
                        className={`${
                          leftNavOpen && (activeResourceType === "poll" || activeResourceType === "pop-quiz")
                            ? StylesB.resource_select_icon_active
                            : StylesB.resource_select_icon
                        } my-auto mx-1 pointer`}
                        style={{
                          objectFit: `contain`,
                          width: `17px`,
                          height: `17px`,
                          color: "#fff",
                        }}
                      />
                    </OverlayTrigger>
                  ) : (
                    ""
                  )}
                  <OverlayTrigger overlay={<Tooltip>Add checklist</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "checklist")
                        openNewWin("Poll", "checklist", topic_id, module_id, data)
                      }}
                      src={checklist}
                      title={`Add Checklist`}
                      alt={`add checklist button`}
                      className={`${
                        leftNavOpen && (activeResourceType === "poll" || activeResourceType === "checklist")
                          ? StylesB.resource_select_icon_active
                          : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger overlay={<Tooltip>Add case-study</Tooltip>}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation()
                        openNavWithResourceSection("Poll", "casestudy")
                        openNewWin("Poll", "casestudy", topic_id, module_id, data)
                      }}
                      src={casestudy}
                      title={`Add Case-Study`}
                      alt={`add case study button`}
                      className={`${
                        leftNavOpen && (activeResourceType === "poll" || activeResourceType === "casestudy")
                          ? StylesB.resource_select_icon_active
                          : StylesB.resource_select_icon
                      } my-auto mx-1 pointer`}
                      style={{
                        objectFit: `contain`,
                        width: `17px`,
                        height: `17px`,
                        color: "#fff",
                      }}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </div>
            {course.course_type === "0" && selectedStageName === "Workshop" ? (
              <BreakoutSessionSection
                course={course}
                template_id={selectedTemplate}
                datum={topicsData["topic_" + topic_id]}
                setCurrentSessions={(value) => {
                  changeCurrentSession(topic_id, value)
                }}
                handleSave={handleSave}
                setSelectedBreakoutForEdit={(value) => setSelectedBreakoutForEdit(value)}
                setCreateBreakoutSessionOpened={(value) => setCreateBreakoutSessionOpened(value)}
                setSelectedTopicForBreakout={(value) => setSelectedTopicForBreakout(value)}
                draft_content={hashValue === "real" ? `false` : `true`}
                isPermitted={isPermitted}
              />
            ) : (
              ""
            )}
          </div>
        )
      } else {
        return showLoader()
      }
    } else return null
  }
  function showLoader() {
    return (
      <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
        <Spinner animation="border" variant="secondary" size="sm" />
      </Col>
    )
  }

  function showTopicsButtons(module_id) {
    let stage_data = modulesData[selectedStage]
    if (stage_data && stage_data[`module_` + module_id] && stage_data[`module_` + module_id].topics) {
      let module_datum = stage_data[`module_` + module_id]
      if (stage_data[`module_` + module_id].topics.length >= 0) {
        return (
          <Droppable
            isDropDisabled={createBreakoutSessionOpened || showCommonItemsModal || !isPermitted}
            droppableId={`module_` + module_id}
            type={`topicDraggable`}
          >
            {(provided, snapshot) => (
              <React.Fragment>
                <div className={`bg-transparent`} ref={provided.innerRef} style={getTopicListStyle(snapshot.isDraggingOver)}>
                  {stage_data[`module_` + module_id].topics.length > 0 ? (
                    <>
                      {stage_data[`module_` + module_id].topics.map((datum, index) => {
                        return (
                          <Draggable
                            key={datum.id + `_topic_name_` + index}
                            draggableId={datum.id.toString() + "-topic" + "_" + index.toString()}
                            index={index}
                            isDragDisabled={!isPermitted}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`border_on_hover bg-transparent float-none`}
                                style={getTopicItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                {showDroppableData(datum, "topic", module_id, module_datum, index)}
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                    </>
                  ) : (
                    <div className="text-center py-3 justify-content-center">
                      <p
                        style={{
                          fontSize: "13px",
                          margin: "0px",
                          color: "#E0E2E5",
                          marginBottom: "5px",
                        }}
                      >
                        No topics created for this {selectedStageName === "Workshop" && course.course_type !== "1" ? "session" : "module"}!
                      </p>
                    </div>
                  )}

                  {stage_data[`module_` + module_id].topics_next ? (
                    <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                      <Spinner animation="border" variant="secondary" size="sm" />
                    </Col>
                  ) : (
                    ""
                  )}
                  {provided.placeholder}
                  {/*showVisibleTopicBtn(module_id,modulesData[`module_`+module_id].topics.length)*/}
                </div>
                {stage_data[`module_` + module_id].topics.length > 0 ? (
                  showDroppableSubData(stage_data, "topic", module_id, module_datum)
                ) : (
                  <div className="d-flex justify-content-center">{showDroppableSubData(stage_data, "topic", module_id, module_datum)}</div>
                )}
              </React.Fragment>
            )}
          </Droppable>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function showCurrentModuleData(module_id) {
    var isLoaded = currentModuleFetched
    if (modulesData[selectedStage] && modulesData[selectedStage][`module_` + module_id] !== undefined) {
      let datum = modulesData[selectedStage][`module_` + module_id]
      return (
        <Card className={`${StylesB.topic_card} w-100`}>
          <Card.Body className="p-12px">{showTopicsButtons(module_id)}</Card.Body>
        </Card>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function showCourseDays(datum) {
    if (courseDays > 0) {
      let days_divs = []
      for (var i = 1; i <= courseDays; i++) {
        let current_day = i
        days_divs.push(
          <p
            key={"day_" + i.toString() + "_" + datum.id}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleSave("course_day", current_day, datum, "module")
            }}
            className={`${current_day === datum.course_day ? StylesB.module_selected_day : ""} border_on_hover pointer`}
            style={{
              boxShadow: "0px 3px 6px #00000029",
              opacity: 0.5,
              marginBottom: "0px",
              marginRight: "6px",
              marginLeft: "6px",
              fontSize: "13px",
              padding: "5px 10px",
              minWidth: "63px",
              textAlign: "center",
              borderRadius: "3px",
              backgroundColor: "#586886",
              height: "30px",
              color: "#D3D5E2",
            }}
          >
            <span>Day {i}</span>
          </p>
        )
      }
      return days_divs
    } else {
      return null
    }
  }

  function showModuleName(datum) {
    if (selectedStageName === "Workshop") {
      let start_date = datum.start_date ? utcDateTime.utcDateTime(datum.start_date, "time") : "00:00"
      let end_date = datum.end_date ? utcDateTime.utcDateTime(datum.end_date, "time") : "00:00"
      if (course.course_type === "0") {
        return `${datum.name} (${start_date} -  ${end_date}, Day ${datum.course_day})`
      } else if (course.course_type === "3") {
        return `${datum.name} (${start_date} -  ${end_date})`
      } else {
        return `${datum.name}`
      }
    } else {
      return `${datum.name}`
    }
  }
  function showTopicName(datum) {
    if (course.course_type !== "1" && selectedStageName === "Workshop") {
      return `${datum.name} (${datum.duration} Mins)`
    } else {
      return `${datum.name}`
    }
  }

  function showDroppableData(datum, type, module_id = null, topic_module = null, index = null) {
    if (type === "module") {
      return (
        <React.Fragment>
          <div
            variant="none"
            id={"modules_" + datum.id}
            className={`${Styles.font_16} ${selectedModuleToggle[datum.id]} pr-2 shadow-none d-flex`}
            style={{
              color: `#F2F4FF`,
              justifyContent: "space-between",
              backgroundColor: datum.module_type === "5" ? "#384257" : "#42506C",
              borderRadius: datum.module_type === "5" && course.course_type === "0" ? "8px" : "8px 8px 0px 0px",
            }}
          >
            <Col xs="10" sm="10" md="10" lg="10" className="d-flex pl-0 align-items-center">
              <div
                onClick={(e) => {
                  datum.module_type === "5" && course.course_type === "0" ? void 0 : e.stopPropagation()
                  e.preventDefault()
                  setSelectedModule(datum.id)
                  toggleModule(e, datum.id)
                }}
                className={`d-inline-flex pl-2 pr-3 py-1 align-items-center h-100`}
                style={{
                  backgroundColor: "#38445C",
                  borderRadius: "9px 0px 0px 0px",
                  borderRight: "1px solid #FFD80035",
                }}
              >
                <img
                  src={DragHandleIcon}
                  alt={`drag-handle`}
                  className={`my-auto mr-1 pointer`}
                  style={{
                    objectFit: `contain`,
                    height: `13px`,
                    width: "16px",
                  }}
                />
                <p
                  className={`mb-0 pl-2`}
                  style={{
                    fontSize: "19px",
                    fontWeight: "500",
                    color: "#FFFFFF70",
                  }}
                >
                  {module_id + 1}
                </p>
              </div>
              <NameInputField
                datum={datum}
                handleSave={handleSave}
                moduleEdited={moduleEdited}
                course={course}
                selectedStageName={selectedStageName}
                type="module"
              />
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip placement="bottom" className="classroom-tooltip" id={`"tooltip-${datum.id}"`}>
                    <div>
                      {datum.description
                        ? "View Description"
                        : selectedStageName === "Workshop" && course.course_type !== "1"
                        ? "Add Session Objective"
                        : "Add Module Description"}
                    </div>
                  </Tooltip>
                }
              >
                <div className={`mx-3  `}>
                  <img
                    src={datum.description ? AddedDescriptionIcon : AddDescriptionIcon}
                    alt={`add-description `}
                    onClick={() => {
                      setShowAddDescriptionModal(true)
                      setActiveDatum(datum)
                    }}
                    className={`my-auto mr-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      height: `auto`,
                      width: "auto",
                    }}
                  />
                </div>
              </OverlayTrigger>
              {datum.module_type === "5" && course.course_type === "0" ? (
                <div style={{ borderLeft: "1px solid #ffffff40" }}>
                  <SessionTimingField
                    moduleErrors={moduleErrors}
                    validateModule={validateModule}
                    datum={datum}
                    handleSave={handleSave}
                    course={course}
                    selectedStageName={selectedStageName}
                  />
                </div>
              ) : (
                ""
              )}
            </Col>
            <div className="d-flex">
              {datum.module_type === "5" && course.course_type === "0" ? (
                ""
              ) : (
                <Button
                  variant="transparent"
                  id={`${selectedModuleToggle[datum.id] ? `disable` : ``}click_module${datum.id}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSelectedModule(datum.id)
                    toggleModule(e, datum.id)
                  }}
                  className={`p-0 m-0 border-0 shadow-none text-white`}
                >
                  <FontAwesomeIcon
                    className="mr-2 pointer"
                    icon={selectedModuleToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
                    style={{
                      width: `16px`,
                      height: `16px`,
                      margin: `auto 0px`,
                    }}
                  />
                </Button>
              )}
              {isPermitted && (
                <img
                  src={DeleteIcon}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setDmodal(true)
                    setSelectedDeletingModule(datum)
                  }}
                  alt={`delete icon`}
                  className={`my-auto mr-1 pointer`}
                  style={{ objectFit: `contain`, height: `13px` }}
                />
              )}
            </div>
          </div>
          {selectedModuleToggle[datum.id] ? (
            <Col
              xs="12"
              sm="12"
              md="12"
              style={{
                padding: "0px",
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: datum.module_type === "5" ? "#2D374C" : "inherit",
              }}
            >
              <div style={{ padding: "12px", borderBottom: "1px dashed #E0E2E5" }}>
                {/* <DescriptionInputField datum={datum} handleSave={handleSave} moduleEdited={moduleEdited} course={course} selectedStageName={selectedStageName}  /> */}
                {selectedStageName === "Workshop" && course.course_type !== "1" ? (
                  <div
                    className={`${datum.module_type === "5" ? StylesB.break_description_input : StylesB.description_input} d-flex py-1`}
                    style={{ marginTop: "12px", borderRadius: "8px" }}
                  >
                    <SessionTimingField
                      moduleErrors={moduleErrors}
                      validateModule={validateModule}
                      datum={datum}
                      handleSave={handleSave}
                      course={course}
                      selectedStageName={selectedStageName}
                    />
                    {course.course_type === "0" ? (
                      <div
                        className="d-flex align-items-center"
                        style={{
                          width: "calc(100% - 300px)",
                          overflowX: "auto",
                        }}
                      >
                        {showCourseDays(datum)}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              {showCurrentModuleData(datum.id)}
            </Col>
          ) : (
            <React.Fragment>
              {datum.module_type === "5" && course.course_type === "0" ? (
                ""
              ) : (
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  style={{
                    padding: "9px",
                    backgroundColor: "#303C54",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <p
                    style={{ marginBottom: "0px", fontSize: "13px" }}
                    className="pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setSelectedModule(datum.id)
                      toggleModule(e, datum.id)
                    }}
                  >
                    <u>
                      {datum.topic_count} {course.course_type === "3" && selectedStageName === "Workshop" && datum.module_type !== "5" ? "Lesson" : "Topic"}
                      {datum.topic_count > 1 ? "s" : ""} added
                    </u>
                  </p>
                </Col>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )
    } else if (type === "topic") {
      let modouleindex = modules[selectedStage].modules.findIndex((res) => res.id === module_id)
      return (
        <React.Fragment>
          <div
            id={"topics_" + datum.id}
            className={`${Styles.font_16} pr-2 shadow-none d-flex`}
            style={{
              color: `#F2F4FF`,
              justifyContent: "space-between",
              backgroundColor: topic_module.module_type === "5" ? "#343e51" : "#42506C",
              borderRadius: selectedTopicToggle[datum.id] ? "8px 8px 0px 0px" : "8px",
            }}
          >
            <Col xs="6" sm="6" md="6" lg="6" className="d-flex pl-0 align-items-center">
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setSelectedTopic(datum.id)
                  toggleTopic(e, datum.id)
                }}
                className={`d-inline-flex pl-2 pr-3 py-1 align-items-center h-100`}
                style={{
                  backgroundColor: "#38445C",
                  borderRadius: selectedTopicToggle[datum.id] ? "9px 0px 0px 0px" : "9px 0px 0px 9px",
                  borderRight: "1px solid #FFD80035",
                  borderBottom: selectedTopicToggle[datum.id] ? "1px solid #FFD80035" : "",
                }}
              >
                <img
                  src={DragHandleIcon}
                  alt={`topic-drag-handle`}
                  className={`my-auto mr-1 pointer`}
                  style={{
                    objectFit: `contain`,
                    height: `13px`,
                    width: "16px",
                  }}
                />
                <p
                  className={`mb-0 pl-2`}
                  style={{
                    fontSize: "19px",
                    fontWeight: "500",
                    color: "#FFFFFF70",
                  }}
                >{`${modouleindex + 1}.${index + 1}`}</p>
              </div>
              <NameInputField
                datum={datum}
                handleSave={handleSave}
                moduleEdited={moduleEdited}
                course={course}
                selectedStageName={selectedStageName}
                type="topic"
              />
            </Col>

            <div className="d-flex px-1 justify-content-between align-items-center">
              {selectedStageName === "Workshop" && isMuralEnabled ? (
                <div>
                  <ActivityRoomWokSpace
                    mural_workspace={course_template?.activity_workspace}
                    activity_workspace={datum?.id ? datum : {}}
                    setModulesData={setModulesData}
                    modouleindex={modouleindex}
                    modulesData={modulesData}
                    type={"topic"}
                    selectedStage={selectedStage}
                    module_id={module_id}
                    topicIndex={index}
                    mural_account={course?.mural_account}
                    isPermitted={isPermitted}
                  />
                </div>
              ) : (
                ""
              )}
              <div>
                <FontAwesomeIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setSelectedTopic(datum.id)
                    toggleTopic(e, datum.id)
                  }}
                  className="mr-2 pointer"
                  icon={selectedTopicToggle[datum.id] ? faChevronCircleUp : faChevronCircleDown}
                  style={{ width: `16px`, height: `16px`, margin: `auto 0px` }}
                />
                {isPermitted && (
                  <img
                    src={DeleteIcon}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setSelectedModule(module_id)
                      setDmodal(true)
                      datum.module = module_id
                      setSelectedDeletingTopic(datum)
                    }}
                    alt={`topic-delete`}
                    className={`my-auto mr-1 pointer`}
                    style={{ objectFit: `contain`, height: `13px` }}
                  />
                )}
              </div>
            </div>
          </div>
          {selectedTopicToggle[datum.id] ? showTopicsData(datum, false, module_id, topic_module) : ``}
        </React.Fragment>
      )
    }
  }

  function gotoNextSection() {
    let stages = course_template.stages
    let is_modules_valid = true
    if (course.course_type === "0" && selectedStageName === "Workshop") {
      is_modules_valid = validateModules()
    }
    if (is_modules_valid) {
      if (stages[stages.length - 1].id === selectedStage) {
        navigate(`/app/companies/${course.company}/courses/course-publish/${course.id}`)
      } else {
        let current_stage = stages.filter((stage) => stage.id === selectedStage)[0]
        let stage_index = stages.indexOf(current_stage)
        let next_stage = stages[stage_index + 1]
        setSelectedStage(next_stage.id)
        setSelectedStageName(next_stage.name)
      }
    }
  }
  function validateModules() {
    let is_valid = true
    let workshop_modules = modules[selectedStage].modules
    for (var i = 0; i < workshop_modules.length; i++) {
      let current_module = workshop_modules[i]
      if (!validateModule(current_module)) {
        is_valid = false
        break
      }
    }
    return is_valid
  }
  function validateModule(current_module, from_next_button = true) {
    if (current_module.start_date && current_module.end_date) {
      let start_time = calculateTime(new Date(current_module.start_date))
      let end_time = calculateTime(new Date(current_module.end_date))
      if (end_time > start_time) {
        handleModuleError(current_module.id, "")
        return true
      } else {
        handleModuleError(current_module.id, "End time should be greater than start time")
        if (from_next_button) {
          if (!(current_module.module_type === "5" && course.course_type === "0") && !selectedModuleToggle[current_module.id]) {
            setSelectedModule(current_module.id)
            toggleModule(null, current_module.id)
          }
          scrollToSpecific(null, "modules_" + current_module.id, "module")
        }
        return false
      }
    } else {
      handleModuleError(current_module.id, "Start time and end time is required")
      if (from_next_button) {
        if (!(current_module.module_type === "5" && course.course_type === "0") && !selectedModuleToggle[current_module.id]) {
          setSelectedModule(current_module.id)
          toggleModule(null, current_module.id)
        }
        scrollToSpecific(null, "modules_" + current_module.id, "module")
      }
      return false
    }
  }
  function handleModuleError(module_id, error) {
    let errors = { ...moduleErrors }
    errors[module_id] = error
    setModuleErrors(errors)
  }
  function calculateTime(date) {
    return date.getMinutes() + date.getHours() * 60
  }

  function createModuleOrShowWarning(event) {
    if (course.course_type === "3") {
      let session_count = course.cohort_schedule.session_count
      let all_modules = modules[selectedStage].modules
      let session_module_count = all_modules.filter((session) => session.module_type === "3").length
      if (session_module_count < session_count) {
        setModuleCreated(false)
        createModule(event)
      } else {
        setShowScheduleModal(true)
      }
    } else {
      setModuleCreated(false)
      createModule(event)
    }
  }

  function showDroppableSubData(stage_data, type, module_id = null, topic_module = null) {
    if (type === "module") {
      return (
        <React.Fragment>
          {stage_data.next_url !== null && stage_data.next_loader ? (
            ""
          ) : (
            <div
              className="d-flex col-12"
              style={{
                justifyContent: "space-between",
                padding: "12px",
                position: "absolute",
                bottom: "0px",
                boxShadow: "0px -3px 12px #00000099",
                borderRadius: "0px 0px 8px 8px",
                backgroundColor: "#303C54",
              }}
            >
              <React.Fragment>
                <div className="d-flex">
                  <Button
                    disabled={(!moduleCreated && showSuggestDayModal) || !isPermitted}
                    className={`${StylesB.add_button_large} py-1 border_on_hover d-flex`}
                    variant="none"
                    onClick={(e) => {
                      e.stopPropagation()
                      createModuleOrShowWarning(e)
                    }}
                  >
                    {moduleCreated || !showSuggestDayModal ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                    Add {selectedStageName === "Workshop" && course.course_type !== "1" ? "Session" : "Module"}
                  </Button>
                  {/* {selectedStageName === "Workshop" && course.course_type === "0" ? (
                    <Button
                      disabled={(!moduleCreated && showSuggestDayModal) || !isPermitted}
                      className={`${StylesB.add_button_large} py-1 border_on_hover d-flex align-items-center ml-2`}
                      variant="none"
                      onClick={(e) => {
                        e.stopPropagation()
                        setModuleCreated(false)
                        createModule(e, "5")
                      }}
                    >
                      {moduleCreated || !showSuggestDayModal ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                      <FontAwesomeIcon className={`mr-2 my-0`} icon={faMugHot} size="lg" />
                      <span>Add a break</span>&nbsp;
                    </Button>
                  ) : (
                    ""
                  )} */}
                  <Button
                    className={`${StylesB.add_button_large} py-1 border_on_hover d-flex ml-2 px-3`}
                    variant="none"
                    onClick={(e) => {
                      e.stopPropagation()
                      gotoNextSection(stage_data)
                    }}
                  >
                    &emsp;Next&emsp;
                  </Button>
                </div>
                <div className="d-flex pr-3" style={{ alignItems: "center" }}>
                  <img src={CheckIcon} alt={`white check`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, width: `13px` }} />
                  <p
                    style={{
                      fontSize: "12px",
                      margin: "0px",
                      color: "#E0E2E5",
                    }}
                  >
                    All changes are saved
                  </p>
                </div>
              </React.Fragment>
            </div>
          )}
        </React.Fragment>
      )
    } else if (type === "topic") {
      let module_datum = stage_data[`module_` + module_id]
      return (
        <div className="d-flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
          {currentModuleTopicsFetched && stage_data[`module_` + module_id] && stage_data[`module_` + module_id].topics_next === null ? (
            <Button
              disabled={!topicCreated || !isPermitted}
              className={`${topic_module.module_type === "5" ? StylesB.add_break_button : StylesB.add_button} border_on_hover d-flex`}
              variant="none"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setTopicCreated(false)
                setSelectedModule(module_datum.id)
                selectedModuleToggle[module_datum.id] = true
                createTopic(e, module_datum.id, null, false, topic_module.module_type)
              }}
            >
              {topicCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
              Add {course.course_type === "3" && selectedStageName === "Workshop" && topic_module.module_type !== "5" ? "Lesson" : "Topic"}
            </Button>
          ) : (
            ``
          )}
        </div>
      )
    }
  }
  function showModulesButtons() {
    let stage_data = modules[selectedStage]
    if (stage_data.modules.length > 0) {
      return (
        <Droppable
          isDropDisabled={createBreakoutSessionOpened || showCommonItemsModal || !isPermitted}
          droppableId={`moduleDraggable`}
          type={createBreakoutSessionOpened ? `moduleDraggabledisabled` : `moduleDraggable`}
        >
          {(provided, snapshot) => (
            <React.Fragment>
              <div
                className={`${showOnlyWorkshop ? StylesB.border_radius_8 : ""} col-12 pb-5`}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {stage_data.modules.map((datum, index) => (
                  <React.Fragment key={datum.id + `_module_section_` + index.toString()}>
                    {selectedStageName === "Workshop" &&
                    course.course_type === "3" &&
                    datum.module_type === "3" &&
                    ((stage_data.modules[index - 1] && stage_data.modules[index - 1].module_type !== "5") || (index === 0 && datum.module_type !== 5)) ? (
                      <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                        <Button
                          disabled={!moduleCreated}
                          className={`${StylesB.add_break_button_large} py-1 border_on_hover d-flex align-items-center mb-2`}
                          variant="none"
                          onClick={(e) => {
                            e.stopPropagation()
                            setModuleCreated(false)
                            createModule(e, "5", index)
                          }}
                        >
                          {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                          <img
                            src={ResourceColorIcon}
                            alt={`resource-color-icon`}
                            className={`my-auto mr-1 pointer`}
                            style={{
                              objectFit: `contain`,
                              height: `16px`,
                              width: "16px",
                            }}
                          />
                          <span>
                            <u>Add pre/post session content</u> (optional)
                          </span>
                          &nbsp;
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                    <Draggable
                      key={datum.id + `_module_name_` + index}
                      draggableId={datum.id.toString() + "-module" + "_" + index.toString()}
                      index={index}
                      isDragDisabled={!isPermitted ? true : course.course_type === "3" ? true : false}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`border_on_hover`}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          {showDroppableData(datum, "module", index)}
                        </div>
                      )}
                    </Draggable>
                  </React.Fragment>
                ))}
                {stage_data.next_loader ? (
                  <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                    <Spinner animation="border" variant="secondary" size="sm" />
                  </Col>
                ) : (
                  ""
                )}
                {selectedStageName === "Workshop" && course.course_type === "3" && stage_data.modules[stage_data.modules.length - 1].module_type !== "5" ? (
                  <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                    <Button
                      disabled={!moduleCreated}
                      className={`${StylesB.add_break_button_large} py-1 border_on_hover d-flex align-items-center mb-2`}
                      variant="none"
                      onClick={(e) => {
                        e.stopPropagation()
                        setModuleCreated(false)
                        createModule(e, "5")
                      }}
                    >
                      {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                      <img
                        src={ResourceColorIcon}
                        alt={`resource-color-icon`}
                        className={`my-auto mr-1 pointer`}
                        style={{
                          objectFit: `contain`,
                          height: `16px`,
                          width: "16px",
                        }}
                      />
                      <span>
                        <u>Add pre/post session content</u> (optional)
                      </span>
                      &nbsp;
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                {provided.placeholder}
              </div>
              {showDroppableSubData(stage_data, "module")}
            </React.Fragment>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-3">
          <p style={{ fontSize: "14px", margin: "0px", color: "#E0E2E5" }}>
            No {selectedStageName === "Workshop" && course.course_type !== "1" ? "sessions" : "modules"} created yet!
          </p>
          <div className="d-flex justify-content-center pt-2">
            <Button
              disabled={!moduleCreated}
              className={`${StylesB.add_button_large} text-color-1 border_on_hover d-flex`}
              variant="none"
              onClick={(e) => {
                e.stopPropagation()
                createModuleOrShowWarning(e)
              }}
            >
              {moduleCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
              Add {selectedStageName === "Workshop" && course.course_type !== "1" ? "Session" : "Module"}
            </Button>
          </div>
        </Col>
      )
    }
  }
  function displayWorkshopInfo() {
    if (course.course_type === "0") {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="d-flex py-3">
          <Col lg="6" md="6" sm="6" xs="6">
            <div style={{ color: "#E0E2E5", fontSize: "14px" }}>
              <p style={{ opacity: 0.5, fontSize: "15px" }}>Before you set up the course content for live classroom</p>
              <p style={{ fontSize: "17px", marginBottom: "5px" }}>Sessions & Topics</p>
              <p style={{ marginBottom: "8px" }}>
                Your entire class will be divided into Sessions. A session is a finite period dedicated to a particular subject. The length and number of
                sessions can be defined.
              </p>
              <p style={{}}>
                Each session will be divided into topics. The number of topics in a particular session can be defined depending on the session length.
              </p>
            </div>
            <Button
              className={`border_on_hover mb-0`}
              onClick={(e) => {
                e.stopPropagation()
                setShowWorkshopInfo(false)
                setModuleCreated(false)
                createModule(null)
              }}
              style={{
                backgroundColor: "#303c54",
                padding: "7px 16px",
                borderRadius: "18px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                fontSize: "14px",
                border: "1px solid transparent",
              }}
            >
              Set Up Live Worskhop&nbsp;{" "}
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                style={{
                  height: 14,
                  width: 14,
                  borderRadius: "50%",
                  backgroundColor: "#E0E2E5",
                  color: "#212c42",
                  padding: "2px 2px 2px 3px",
                }}
              />
            </Button>
          </Col>
          <Col lg="6" md="6" sm="6" xs="6">
            <img src={WorkshopInfo} alt={`list`} className={`my-auto`} style={{ objectFit: `contain` }} />
          </Col>
        </Col>
      )
    } else if (course.course_type === "3") {
      let schedule = course.cohort_schedule
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-5">
          <Col lg="12" md="12" sm="12" xs="12">
            <div style={{ color: "#E0E2E5", fontSize: "14px" }}>
              <p
                style={{
                  fontSize: "18px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                {schedule.session_count} Sessions
              </p>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                scheduled to repeat every {schedule.repeat_count} {schedule.repeat_type}
              </p>
              <p style={{ opacity: 0.5, fontSize: "15px", marginBottom: "10px" }}>Before you set up the course content for Cohort Sessions</p>
              <p style={{ fontSize: "17px", marginBottom: "5px" }}>Sessions & Lessons</p>
              <p style={{ marginBottom: "8px" }}>
                Your entire class will be divided in to Sessions. A session is a finite period of time dedicated to a particular subject. You can define the
                length of a session and thus the no of session in a particular class
              </p>
              <p style={{}}>
                Each session will be divided in to lessons. You can define any no of lessons in a particular topic depending on the session Length.
              </p>
            </div>
            <Button
              className={`border_on_hover mb-0`}
              onClick={(e) => {
                e.stopPropagation()
                setShowWorkshopInfo(false)
              }}
              style={{
                backgroundColor: "#303c54",
                padding: "7px 16px",
                borderRadius: "18px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                fontSize: "14px",
                border: "1px solid transparent",
              }}
            >
              Set Up Cohort Sessions&nbsp;{" "}
              <FontAwesomeIcon
                icon={faChevronRight}
                size="sm"
                style={{
                  height: 14,
                  width: 14,
                  borderRadius: "50%",
                  backgroundColor: "#E0E2E5",
                  color: "#212c42",
                  padding: "2px 2px 2px 3px",
                }}
              />
            </Button>
          </Col>
        </Col>
      )
    } else if (course.course_type === "1") {
      return sequenceView()
    } else return null
  }
  function showStageContent() {
    if (selectedStage) {
      let stage_data = modules[selectedStage]
      if (stage_data !== undefined && stage_data.modules !== undefined && stage_data.loader !== undefined) {
        if (selectedStageName === "Workshop" && showWorkshopInfo && (course.course_type === "0" || course.course_type === "3" || course.course_type === "1")) {
          return displayWorkshopInfo()
        } else {
          return (
            <div id="scrollable-div" style={{ maxHeight: "calc(100vh - 160px)", overflowY: "auto" }}>
              {showModulesButtons()}
            </div>
          )
        }
      } else {
        return (
          <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
            <Spinner animation="border" variant="secondary" size="sm" />
          </Col>
        )
      }
    } else {
      return null
    }
  }
  function switchStage(stage) {
    let is_modules_valid = true
    let stages = course_template.stages
    if (course.course_type === "0" && selectedStageName === "Workshop" && stage.id === stages[stages.length - 1].id) {
      is_modules_valid = validateModules()
    }
    if (is_modules_valid) {
      setSelectedStage(stage.id)
      setSelectedStageName(stage.name)
    }
  }
  function showCourseStages() {
    if (course_template && course_template.stages) {
      return course_template.stages.map((node, index) => {
        return (
          <div
            key={index.toString() + node.name}
            onClick={(e) => {
              e.stopPropagation()
              switchStage(node)
            }}
            className={`${selectedStage === node.id ? StylesB.course_nav_item_active : ""} ${StylesB.course_nav_item} px-3 py-2 fs-15px pointer`}
          >
            {node.name}
          </div>
        )
      })
    } else return null
  }
  function showCourseContent() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        className="px-0"
        style={{
          backgroundColor: "#212C42",
          borderRadius: "8px",
          boxShadow: "0px 3px 12px #00000099",
        }}
      >
        {showOnlyWorkshop ? (
          ""
        ) : (
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="d-inline-flex align-items-center w-100 px-0"
            style={{
              borderBottom: "1px solid #838B9E",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            {showCourseStages()}
            {selectedStageName === "Workshop" && isMuralEnabled ? (
              <WorkSpaceRoom
                activity_workspace={course_template?.id ? course_template : {}}
                setCourseTemplate={setCourseTemplate}
                sourceWorkspace="course-content"
                mural_account={course?.mural_account}
                isPermitted={!isPermitted}
              />
            ) : (
              ""
            )}
            {breakoutGroupsDataFetched && selectedStageName === "Workshop" ? (
              <div className={`pr-3 pl-3 ${!isMuralEnabled && "ml-auto"}`} style={{ color: "#E0E2E5" }}>
                <p
                  onClick={() => {
                    setShowCreatingGroupsModal(true)
                  }}
                  className={`mb-0 d-inline-flex align-items-center pointer `}
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  {" "}
                  <FontAwesomeIcon color="#E0E2E5" className={`mb-0 mr-1`} icon={faUsers} />
                  <u>{breakoutGroupList.length > 0 ? breakoutGroupList.length : `Create`} Groups</u>
                </p>
              </div>
            ) : null}
          </Col>
        )}
        {course.course_type === "1" && !showWorkshopInfo ? showFlowToggle() : ""}
        {showStageContent()}
      </Col>
    )
  }
  function showFlowToggle() {
    return (
      <Col
        lg="12"
        md="12"
        sm="12"
        xs="12"
        style={{
          paddingTop: "12px",
          justifyContent: "flex-end",
          paddingBottom: "5px",
        }}
        className="d-flex"
      >
        <div
          className="align-self-center d-flex"
          style={{
            background: "#42506C",
            border: "1px solid #E0E2E5",
            borderRadius: "9px",
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation()
              changeCourse("1")
            }}
            className="border_on_hover"
            style={{
              padding: "3px 9px",
              background: course.sequence_type === "1" ? "#4F6080" : "#42506C",
              fontWeight: course.sequence_type === "1" ? "bold" : null,
              border: "1px solid transparent",
              borderRadius: "9px 4px 4px 9px",
            }}
            color="warning"
          >
            <p className={`p-0 m-0`} style={{ fontSize: "14px" }}>
              Seq Flow
            </p>
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              changeCourse("0")
            }}
            className="border_on_hover"
            style={{
              padding: "3px 9px",
              background: course.sequence_type === "0" ? "#4F6080" : "#42506C",
              fontWeight: course.sequence_type === "0" ? "bold" : null,
              border: "1px solid transparent",
              borderRadius: "4px 9px 9px 4px",
            }}
            color="warning"
          >
            <p className={`p-0 m-0`} style={{ fontSize: "14px" }}>
              Free Flow
            </p>
          </Button>
        </div>
      </Col>
    )
  }
  function changeCourse(value) {
    if (value !== course.sequence_type) {
      let current_data = { ...course }
      current_data.sequence_type = value
      setCourse(current_data)
    }
  }

  const sequenceView = () => {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className={`py-5 d-flex flex-column px-4 bg-2 w-100 my-3 br-9px inner-element-box-shadow`}>
        <div className={`text-color-1 fs-18px font-weight-bold mb-1`} style={{ opacity: "0.56" }}>
          Before you set up the course content
        </div>
        <div className={`text-color-1 fs-20px font-weight-bold mb-3`}>How do you want your students to learn?</div>
        <NewCheckBox change_value="1" checked={course.sequence_type === "1" ? true : false} changeInParent={(value) => changeCourse(value)}>
          <div className={`fs-20px font-weight-bold mb-1`}>Sequential flow</div>
          <div>
            Select this if you want your students to learn your courses in a proper sequence. Sequential flow puts your digital course into a specific
            chronological pattern.
          </div>
        </NewCheckBox>
        <NewCheckBox change_value="0" checked={course.sequence_type === "0" ? true : false} changeInParent={(value) => changeCourse(value)}>
          <div className={`fs-20px font-weight-bold mb-1`}>Free Flow</div>
          <div>
            Select this if you want your students to learn your courses in the sequence they prefer. Free flow empowers your learners to choose the lessons they
            want to learn first.
          </div>
        </NewCheckBox>
        <div className={`mt-2`}>
          <Button
            className={`d-flex align-items-center border_on_hover text-color-1 turient-body-text-color mb-0`}
            onClick={(e) => {
              e.stopPropagation()
              handleSubmit()
              setSelectSequecing(false)
              setShowWorkshopInfo(false)
              createModule(null)
            }}
            style={{
              backgroundColor: "#586886",
              padding: "7px 16px",
              borderRadius: "18px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              fontSize: "14px",
              border: "1px solid transparent",
            }}
          >
            Set up course Content&nbsp;
            <FontAwesomeIcon
              icon={faChevronRight}
              size="sm"
              style={{
                height: 14,
                width: 14,
                borderRadius: "50%",
                backgroundColor: "#E0E2E5",
                color: "#212c42",
                padding: "2px 2px 2px 3px",
                marginLeft: "5px",
              }}
            />
          </Button>
        </div>
      </Col>
    )
  }

  async function fetchBreakoutGroupsData() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/breakoutgroups/?template=${selectedTemplate}&parent_template=${course.course_template.id}&draft_content=${hashValue === "real" ? `false` : `true`}`,
        { headers: login_credentials }
      )
      .then((res) => {
        setBreakoutGroupList(res.data.results)
        setBreakoutGroupsDataFetched(true)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  async function createCommomResourceInstruction() {
    var formData = new FormData()
    if (course?.course_template?.id) {
      formData.append("template", selectedTemplate)
      formData.append("instruction", "")
      if (breakoutGroupInstructions.length > 0) {
        let value = (parseFloat(breakoutGroupInstructions[breakoutGroupInstructions.length - 1].sequence) + 10).toFixed(20)
        formData.append("sequence", value)
      }
      // formData.append('breakout_groups', )
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/`, formData, { headers: login_credentials })
        .then((res) => {
          let dummy = [...breakoutGroupInstructions]
          dummy.push(res.data)
          setBreakoutGroupInstructions(dummy)
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }
  async function updateCommomResourceInstruction(value, template, datum, index, type = null, group_id = null, instructions = null) {
    var formData = new FormData()
    formData.append("template", template)
    if (type === "instruction") {
      formData.append("instruction", value)
    }
    if (type === "breakout_groups" || type === "breakout_groups_instruction") {
      let dummy1 = { ...datum }
      let dummy = []
      if (dummy1.breakout_groups) {
        dummy = [...dummy1.breakout_groups]
      } else {
        dummy1["breakout_groups"] = []
        dummy = [...dummy1.breakout_groups]
      }
      let fil_arr = dummy.filter((res) => res.id == group_id)
      if (fil_arr.length === 0) {
        let res = {}
        res["id"] = group_id
        let ind = breakoutGroupList.findIndex((res) => res.id == group_id)
        res["title"] = breakoutGroupList[ind].title
        dummy.push(res)
        datum["breakout_groups"] = dummy
      } else {
        let res_arr = []
        for (let i = 0; i < dummy.length; i++) {
          if (dummy[i].id != group_id) {
            res_arr.push(dummy[i])
          }
        }
        datum["breakout_groups"] = res_arr
      }
    }

    for (var k in datum) {
      if (datum[k] !== null) {
        if (k === "start_date" || k === "end_date") {
          if (datum[k]) {
            formData.append(k, new Date(datum[k]).toISOString())
          }
        } else {
          if (k === "breakout_groups") {
            let dummy1 = { ...datum }
            let dummy = [...dummy1.breakout_groups]
            let res_arr = []
            for (let i = 0; i < dummy.length; i++) {
              res_arr.push(dummy[i].id)
            }
            // res_arr.push(value)
            formData.append("breakout_groups", JSON.stringify(res_arr))
          } else {
            // formData.append(k,datum[k])
          }
        }
      }
    }
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/${datum.id}/`, formData, { headers: login_credentials })
      .then((res) => {
        if (type === "instruction") {
          let dummy = { ...instructionsLoader }
          let text = `instruction_` + datum.id
          dummy[text] = false
          setInstructionsLoader(dummy)
        }

        if (type === "instruction" || type === "breakout_groups_instruction") {
          if (instructions) {
            let dummy = [...instructions]
            dummy[index] = res.data
            setBreakoutGroupInstructions(dummy)
          } else {
            let dummy = [...breakoutGroupInstructions]
            dummy[index] = res.data
            setBreakoutGroupInstructions(dummy)
          }
        } else if (type === "breakout_groups") {
          let dummy = [...breakoutGroupResources]
          dummy[index] = res.data
          setBreakoutGroupResources(dummy)
        }
      })
      .catch((error) => {
        console.error("err", error)
      })
  }
  async function fetchBreakoutGroupsResources(template = "", parent_template = "", draft = "") {
    if (template === "") template = selectedTemplate
    if (parent_template === "") parent_template = course.course_template.id
    if (draft === "") draft = hashValue === "real" ? `false` : `true`

    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/common-group-resource/?type=resource&template=${template}&parent_template=${parent_template}&draft_content=${draft}`,
        { headers: login_credentials }
      )
      .then((res) => {
        setBreakoutGroupResources(res.data)
        setBreakoutGroupsResourcesFetched(true)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  async function fetchBreakoutGroupsInstructions() {
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/common-group-resource/?type=instruction&template=${selectedTemplate}&parent_template=${course.course_template.id}&draft_content=${
            hashValue === "real" ? `false` : `true`
          }`,
        { headers: login_credentials }
      )
      .then((res) => {
        setBreakoutGroupInstructions(res.data)
        if (res.data.length === 0) {
          createCommomResourceInstruction()
        }
        setBreakoutGroupInstructionsFetched(true)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }
  async function deleteBreakoutGroupsResources(resource_id, index, type) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/common-group-resource/${resource_id}/?draft_content=${hashValue === "real" ? `false` : `true`}`, {
        headers: login_credentials,
      })
      .then((res) => {
        // let dummy = [...breakoutGroupResources]
        if (type == "resource") {
          let arr = breakoutGroupResources.filter((item) => item.id !== resource_id)
          setBreakoutGroupResources(arr)
        } else {
          let arr = breakoutGroupInstructions.filter((item) => item.id !== resource_id)
          setBreakoutGroupInstructions(arr)
        }
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  function showMainSection() {
    if (course_template) {
      if (course_template.stages.length > 0) {
        return (
          <Col className="px-0" lg="12" md="12" sm="12" xs="12">
            {showCourseContent()}
          </Col>
        )
      } else {
        return stageSelectView()
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  function stageSelectView() {
    return (
      <Col xs="12" sm="12" md="12" lg="12" className={`py-5 d-flex flex-column px-4 bg-2 w-100 my-3 br-9px inner-element-box-shadow`}>
        <div className={`text-color-1 fs-16px font-weight-bold mb-3`}>Please select the class structure</div>
        <NewCheckBox change_value="pre" checked={preworkshopSeleted} changeInParent={(value) => setPreworkshopSeleted(!preworkshopSeleted)}>
          <div className={`fs-20px font-weight-bold mb-1`}>Pre Worskhop</div>
          <div>
            Select this if there is any pre-workshop content for the students to go through. A simple learning layout will be provided for you to setup content
            that your students will go through and come prepared before the live workshop.
          </div>
        </NewCheckBox>
        <NewCheckBox change_value="pre" checked={true} changeInParent={(value) => {}}>
          <div className={`fs-20px font-weight-bold mb-1`}>Live Worskhop</div>
          <div>
            Workshop content for the students to go through. A simple learning layout will be provided for you to setup content that your students will go
            through during the live workshop.
          </div>
        </NewCheckBox>
        <NewCheckBox change_value="post" checked={postworkshopSeleted} changeInParent={(value) => setPostworkshopSeleted(!postworkshopSeleted)}>
          <div className={`fs-20px font-weight-bold mb-1`}>Post Workshop</div>
          <div>
            Select this if there is any post-workshop content for the students to go through. A simple learning layout will be provided for you to setup content
            that your students will go through after the live workshop.
          </div>
        </NewCheckBox>
        <div className={`mt-2`}>
          <Button
            className={`border_on_hover px-4 py-1 turient-body-text-color mb-0`}
            onClick={() => createStages()}
            style={{
              backgroundColor: "#586886",
              borderRadius: "18px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              fontSize: "14px",
              border: "1px solid transparent",
            }}
          >
            {" "}
            Next
          </Button>
        </div>
      </Col>
    )
  }
  function createStages() {
    let stage_name = ""
    if (preworkshopSeleted && postworkshopSeleted) {
      stage_name = "both"
    } else if (preworkshopSeleted) {
      stage_name = "pre"
    } else if (postworkshopSeleted) {
      stage_name = "post"
    } else {
      stage_name = "work"
    }
    axios
      .get(
        process.env.GATSBY_REACT_APP_API_HOST +
          `/default-course-templates/${course.course_template.id}/?stages_to_be_created=${stage_name}&course_id=${location.id}&draft_content=${
            hashValue === "real" ? `false` : `true`
          }`,
        { headers: login_credentials }
      )
      .then((res) => {
        fetchorCreateTemplate()
        fetchCourse()
      })
      .catch((error) => {
        setTopicFetched(false)
        showErrorMessage(error)
      })
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  function onDragEnd(result) {
    if (createBreakoutSessionOpened) {
      setDragEnded(true)
      setDraggedItem(result)
    } else if (showCommonItemsModal) {
      onCommonResourceDragEnd(result)
    } else {
      onCourseContentDragEnd(result)
    }
  }

  function onCommonResourceDragEnd(result) {
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    const preventedResourceType = ["mtf", "quiz", "poll", "checklist", "casestudy", "pop-quiz"].includes(currentSelectedResourcesType)
    // To prevent adding MTF,Quiz,Poll,Checklist,CaseStudy,Pop-Quiz to common resources.
    if (!result.destination || preventedResourceType) {
      return
    }
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId
    if (type === "resource_resourceDraggable") {
      const itemSubItemMap = allResources
      var sourceSubItems, destSubItems, topic_id, module_id

      if (sourceParentId.indexOf("resource_") > -1) {
        sourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
      }

      if (destParentId.indexOf("_groupresource") > -1) {
        let datum = destParentId.split("_")
        let resource_id = datum[0]
        destSubItems = [...breakoutGroupResources]
      }

      /* In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(allResources[sourceParentId.replace("resource_", "")], sourceIndex, destIndex)
        allResources[sourceParentId.replace("resource_", "")] = items
        setAllResourcesChanged(!allResourcesChanged)
      } else {
        var newSourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        var newDestSubItems
        var formData = new FormData()

        newDestSubItems = destSubItems
        // formData.append('add_to_group_resource', 'true')
        setTopicsResourcesFetched(false)

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem["sequence"] = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem["sequence"] = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        if (hashValue !== "real") {
          formData.append("draft_content", "true")
        }
        formData.append(`template`, selectedTemplate)
        formData.append("sequence", draggedItem.sequence)
        let sourcePIdType = sourceParentId.replace("resource_", "")
        if (sourcePIdType === "poll" || sourcePIdType === "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else {
          if (sourcePIdType === "document") {
            formData.append("resource_type", "turientimage")
          } else {
            formData.append("resource_type", sourcePIdType)
          }
        }
        formData.append("resource_id", draggedItem.id)
        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/common-group-resource/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              let dummy = [...breakoutGroupResources]
              dummy.splice(result.destination.index, 0, res.data)
              setBreakoutGroupResources(dummy)
            }
            setTopicsResourcesFetched(true)
          })
          .catch((error) => {
            showErrorMessage(error)
          })
        /*api end*/
      }
    }
  }

  function onCourseContentDragEnd(result) {
    let type = result.source.droppableId.split("_")[0].toString() + `_resourceDraggable`
    // To prevent adding MTF to topic for now.
    if (!result.destination || currentSelectedResourcesType === "mtf") {
      return
    }
    let stage_data = modulesData[selectedStage]
    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    const sourceParentId = result.source.droppableId
    const destParentId = result.destination.droppableId
    if (result.type === "moduleDraggable") {
      let datum = modules[selectedStage].modules
      const items = reorder(datum, sourceIndex, destIndex)
      let currentModule = items[result.destination.index]
      if (result.destination.index === 0) {
        currentModule.sequence = (parseFloat(datum[0].sequence) - 10).toFixed(20)
      } else if (result.destination.index === datum.length - 1) {
        currentModule.sequence = (parseFloat(datum[datum.length - 1].sequence) + 10).toFixed(20)
      } else {
        if (result.source.index > result.destination.index) {
          currentModule.sequence = (
            (parseFloat(datum[result.destination.index - 1].sequence) + parseFloat(datum[result.destination.index].sequence)) /
            2
          ).toFixed(20)
        } else if (result.source.index < result.destination.index) {
          currentModule.sequence = (
            (parseFloat(datum[result.destination.index].sequence) + parseFloat(datum[result.destination.index + 1].sequence)) /
            2
          ).toFixed(20)
        }
      }
      //save the module sequence in api
      handleSave("sequence", currentModule.sequence, currentModule, "module")
      modules[selectedStage].modules = items
    } else if (result.type === "topicDraggable") {
      if (destParentId === sourceParentId) {
        let datum = stage_data[sourceParentId].topics
        const items = reorder(datum, sourceIndex, destIndex)
        let currentTopic = items[result.destination.index]
        if (result.destination.index === 0) {
          currentTopic.sequence = (parseFloat(datum[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === datum.length - 1) {
          currentTopic.sequence = (parseFloat(datum[datum.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(datum[result.destination.index - 1].sequence) + parseFloat(datum[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentTopic.sequence = (
              (parseFloat(datum[result.destination.index].sequence) + parseFloat(datum[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        //save the topic sequence in api
        handleSave("sequence", currentTopic.sequence, currentTopic, "topic")
        modulesData[selectedStage][sourceParentId].topics = items
      } else {
        const sourceSubItems = stage_data[sourceParentId].topics
        const destSubItems = stage_data[destParentId].topics

        let newItems = stage_data[destParentId]

        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = destSubItems
        newDestSubItems.splice(destIndex, 0, draggedItem)

        if (result.destination.index === 0 && destSubItems.length === 1) {
          draggedItem.sequence = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index + 1 === destSubItems.length) {
          draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem.sequence = (
            (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        let module_id = destParentId.split("_")[1].toString()
        draggedItem.module = module_id
        // change topic and sequence using api
        handleSave("sequence", draggedItem.sequence, draggedItem, "topic")
        draggedItem.requested_module = module_id
        modulesData[selectedStage][destParentId] = newItems
      }
    } else if (type === "resource_resourceDraggable") {
      const itemSubItemMap = allResources
      var sourceSubItems, destSubItems, topic_id, module_id

      if (sourceParentId.indexOf("resource_") > -1) {
        sourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
      }

      if (destParentId.indexOf("resource_") > -1) {
        destSubItems = itemSubItemMap[destParentId.replace("resource_", "")]
      } else if (destParentId.indexOf("topic_") > -1) {
        let datum = destParentId.split("_")
        module_id = datum[0]
        topic_id = datum[2]
        destSubItems = topicsResources[`topic_` + topic_id]
      }

      /* In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(allResources[sourceParentId.replace("resource_", "")], sourceIndex, destIndex)
        allResources[sourceParentId.replace("resource_", "")] = items
        setAllResourcesChanged(!allResourcesChanged)
      } else {
        var newSourceSubItems = itemSubItemMap[sourceParentId.replace("resource_", "")]
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        var newDestSubItems
        var formData = new FormData()

        newDestSubItems = topicsResources[`topic_` + topic_id]
        formData.append("add_to_topic", topic_id)
        setTopicsResourcesFetched(false)

        if (result.destination.index === 0 && destSubItems.length === 0) {
          draggedItem["sequence"] = result.destination.index
        } else if (result.destination.index === 0) {
          if (draggedItem) {
            draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index].sequence) - 10).toFixed(20)
          }
        } else if (result.destination.index === destSubItems.length) {
          draggedItem["sequence"] = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
        } else {
          draggedItem["sequence"] = (
            (parseFloat(destSubItems[result.destination.index].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
            2
          ).toFixed(20)
        }

        /*api for creating resource to topic or module*/
        if (hashValue !== "real") {
          formData.append("draft_content", "true")
          formData.append(`stage_id`, selectedStage)
        }
        formData.append("sequence", draggedItem.sequence)
        let sourcePIdType = sourceParentId.replace("resource_", "")
        if (sourcePIdType === "poll" || sourcePIdType === "pop-quiz") {
          formData.append("resource_type", "quiz")
        } else {
          if (sourcePIdType === "document") {
            formData.append("resource_type", "turientimage")
          } else {
            formData.append("resource_type", sourcePIdType)
          }
        }
        formData.append("resource_id", draggedItem.id)

        axios
          .post(process.env.GATSBY_REACT_APP_API_HOST + "/resources/create/", formData, { headers: login_credentials })
          .then((res) => {
            if (res.data) {
              draggedItem.id = res.data.id
              draggedItem.resource_data = res.data.resource_data
              draggedItem.resource_id = res.data.resource_id
              draggedItem.resource_type = res.data.resource_type
              draggedItem.sequence = res.data.sequence
              delete draggedItem["name"]
            }
            setTopicsResourcesFetched(true)
          })
          .catch((error) => {
            showErrorMessage(error)
          })
        /*api end*/
        newDestSubItems.splice(destIndex, 0, draggedItem)
        setAllResourcesFetched(false)
        let temp_data = allResources
        temp_data[sourceParentId.replace("resource_", "")] = newSourceSubItems
        setAllResourcesChanged(!allResourcesChanged)
      }
    } else if (result.source.droppableId.indexOf("topic") > -1) {
      const itemSubItemMap = topicsResources

      let source_topic_id = "topic_" + sourceParentId.split("_")[2]
      let dest_topic_id = "topic_" + destParentId.split("_")[2]

      const sourceSubItems = itemSubItemMap[source_topic_id]
      const destSubItems = itemSubItemMap[dest_topic_id]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const items = reorder(topicsResources[source_topic_id], sourceIndex, destIndex)
        let currentResource = items[result.destination.index]
        let currentData = topicsResources[dest_topic_id]
        if (result.destination.index === 0) {
          currentResource.sequence = (parseFloat(currentData[0].sequence) - 10).toFixed(20)
        } else if (result.destination.index === currentData.length - 1) {
          currentResource.sequence = (parseFloat(currentData[currentData.length - 1].sequence) + 10).toFixed(20)
        } else {
          if (result.source.index > result.destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[result.destination.index - 1].sequence) + parseFloat(currentData[result.destination.index].sequence)) /
              2
            ).toFixed(20)
          } else if (result.source.index < result.destination.index) {
            currentResource.sequence = (
              (parseFloat(currentData[result.destination.index].sequence) + parseFloat(currentData[result.destination.index + 1].sequence)) /
              2
            ).toFixed(20)
          }
        }
        handleSave("sequence", currentResource.sequence, currentResource, "resource")
        setTopicsResourcesFetched(!topicsResourcesFetched)
        topicsResources[source_topic_id] = items
      } else {
        let newSourceSubItems = sourceSubItems
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

        let newDestSubItems = destParentId.split("_")[0].toString() === `resource` ? allResources[props.currentSelectedResourcesType] : destSubItems
        if (destParentId.indexOf("resource") > -1) {
          /*to be present in api success start*/
          draggedItem.dragged_id = draggedItem.id
          draggedItem.id = draggedItem.resource_data.id
          draggedItem.thumbnail_url = draggedItem.resource_data.thumbnail_url
          draggedItem.name = draggedItem.resource_data?.display_name ?? draggedItem.resource_data?.name
          delete draggedItem["resource_data"]
          delete draggedItem["resource_id"]
          delete draggedItem["resource_type"]
          /*to be present in api success end*/
          deleteResource(draggedItem.dragged_id)
        }
        newDestSubItems.splice(destIndex, 0, draggedItem)
        if (destParentId.indexOf("resource") > -1) {
        } else {
          if (result.destination.index === 0 && destSubItems.length === 1) {
            draggedItem.sequence = result.destination.index
          } else if (result.destination.index === 0) {
            if (draggedItem) {
              draggedItem.sequence = (parseFloat(destSubItems[result.destination.index + 1].sequence) - 10).toFixed(20)
            }
          } else if (result.destination.index + 1 === destSubItems.length) {
            draggedItem.sequence = (parseFloat(destSubItems[result.destination.index - 1].sequence) + 10).toFixed(20)
          } else {
            draggedItem.sequence = (
              (parseFloat(destSubItems[result.destination.index + 1].sequence) + parseFloat(destSubItems[parseFloat(result.destination.index) - 1].sequence)) /
              2
            ).toFixed(20)
          }

          draggedItem.topic = destParentId.split("_")[2]

          handleSave("sequence", draggedItem.sequence, draggedItem, "resource")
          draggedItem.requested_topic = destParentId.split("_")[2]
          setTopicsResourcesFetched(!topicsResourcesFetched)
        }
      }
    }
  }

  function onDragStart(result) {
    if (isPermitted) {
      if (result.type === "resourceDraggable") {
      }
    }
  }
  function onDragUpdate(result) {
    if (result.type === "resourceDraggable" && false) {
      let stage_data = modules[selectedStage]
      if (result.destination && result.destination.droppableId === "moduleDraggable") {
        if (stage_data.modules[result.destination.index]) {
          let datum_id = stage_data.modules[result.destination.index].id
          if (selectedModuleToggle[datum_id] !== true) {
            document.getElementById("click_module" + datum_id).click()
          }
        }
      }
    }
  }
  function toggleLeftNav(value) {
    if (value !== leftNavOpen) {
      setLeftNavOpen(value)
    }
  }

  function findScrollableOrNot() {
    let ele = document.getElementById("sections")
    if (ele !== null && ele !== undefined) {
      if (ele.scrollWidth > ele.clientWidth) {
        return true
      } else {
        return false
      }
    }
  }

  function scrollRight() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft += 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function scrollLeft() {
    let scrollCompleted = 0
    let ele = document.getElementById("sections")
    if (ele !== null) {
      var slideVar = setInterval(function () {
        ele.scrollLeft -= 10
        scrollCompleted += 10
        if (scrollCompleted >= 150) {
          window.clearInterval(slideVar)
        }
      }, 50)
    }
  }

  function calculateWidth() {
    let ele = document.getElementById("coursename")
    if (ele !== null) {
      let data = 1100 - parseInt(ele.offsetWidth) - 50
      return { width: data, overflowX: "scroll", flex: "auto" }
    }
  }
  function handleScheduleModalClose() {
    setShowScheduleModal(false)
    setShowCohortSchedule(false)
    setShowScheduleUpdateWarning(true)
  }
  function cohortScheduleModal() {
    return (
      <Modal
        keyboard={false}
        size={showScheduleUpdateWarning ? "md" : "lg"}
        backdrop="static"
        show={showScheduleModal}
        onHide={handleScheduleModalClose}
        centered
        className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
      >
        {showScheduleUpdateWarning ? (
          <div className={`${StylesB.delete_modal_header}`}>
            <img
              onClick={handleScheduleModalClose}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer`}
              style={{
                objectFit: `contain`,
                height: `13px`,
                position: "absolute",
                top: "7px",
                right: "5px",
              }}
            />
          </div>
        ) : (
          ""
        )}
        <Modal.Body className={`${showScheduleUpdateWarning ? "pt-1" : ""}`} style={{ backgroundColor: "#19253b", borderRadius: "8px" }}>
          {showScheduleUpdateWarning ? (
            <React.Fragment>
              <p
                style={{
                  color: "#E0E2E5",
                  fontSize: "15px",
                  marginBottom: "10px",
                }}
              >
                {" "}
                Please update your course schedule to add more sessions{" "}
              </p>
              <div className="">
                <Button
                  className={`${StylesB.add_button_large_no_border_radius} border_on_hover py-2 mb-2`}
                  variant="secondary"
                  onClick={() => {
                    handleScheduleModalClose()
                  }}
                  style={{ width: "100%", border: "1px solid transparent" }}
                >
                  Auto update schedule according to no of sessions
                </Button>
                <Button
                  className={`${StylesB.add_button_large_no_border_radius} border_on_hover py-2`}
                  variant="secondary"
                  onClick={() => {
                    setShowCohortSchedule(true)
                    setShowScheduleUpdateWarning(false)
                  }}
                  style={{ width: "100%", border: "1px solid transparent" }}
                >
                  I want to do it manually
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <div className="align-items-center">
              <CohortSchedule
                setInitiateScheduleValidation={setInitiateScheduleValidation}
                setValidCohortSchedule={setValidCohortSchedule}
                initiateScheduleValidation={initiateScheduleValidation}
                course={course}
                setCompany={(e) => setCourse(e)}
                page_type={"create"}
              />
              <Button
                className={`${StylesB.add_button_large} border_on_hover mt-3`}
                variant="secondary"
                onClick={(e) => {
                  validateFormData()
                }}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                Done
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    )
  }
  function validateFormData() {
    if (course.course_type === "3") {
      if (validCohortSchedule) {
        handleSubmit(null, true)
        handleScheduleModalClose()
      } else {
        setInitiateScheduleValidation(true)
      }
    } else {
      handleSubmit(null, true)
      handleScheduleModalClose()
    }
  }
  function navigateToPublish() {
    let is_modules_valid = true
    if (course.course_type === "0" && selectedStageName === "Workshop") {
      is_modules_valid = validateModules()
    }
    if (is_modules_valid) {
      navigate(`/app/companies/${course.company}/courses/course-publish/${course.id}`)
    }
  }
  function handleCloseBreakoutOverlay() {
    setCreateBreakoutSessionOpened(false)
    setSelectedTopicForBreakout("")
    setSelectedBreakoutForEdit("")
    setCreatedBreakoutSession("")
    setShowAllBreakoutSessions(false)
    if (activeNavItem === "BreakoutSessions") {
      setActiveNavItem("")
      setCurrentNavTab("")
    }
    fetchSelectedTopic(selectedBreakoutForEdit.topic, true)
  }

  function refetchLeftSideResourcesBar(pActiveResType = "") {
    const evt = new CustomEvent("my-refresh-resources", {
      bubbles: true,
      detail: pActiveResType,
    })
    document.dispatchEvent(evt)
    //fetchAllResources(pActiveResType)
  }

  async function createBreakoutGroup(e, last_create = null, value = null) {
    if (e != null) {
      e.preventDefault()
    }
    var formData = new FormData()
    let group_name = ""
    group_name = "Group" + String(breakoutGroupList.length + 1)
    if (course?.course_template?.id) {
      formData.append("template", selectedTemplate)
      formData.append("title", group_name)
      axios
        .post(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups/create/`, formData, { headers: login_credentials })
        .then((res) => {
          let group_data = [...breakoutGroupList]
          group_data.push(res.data)
          setBreakoutGroupList(group_data)
          setCreatedGroup(true)
          setRefetchGroups(false)
        })
        .catch((error) => {
          showErrorMessage(error)
        })
    }
  }

  function deleteBreakoutGroup(id) {
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + "/breakoutgroups/" + id + "/", { headers: DefaultHeader })
      .then((res) => {
        let arr = breakoutGroupList.filter((item) => item.id !== id)

        setBreakoutGroupList(arr)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  async function updateBreakoutGroup(value, id, index, breakoutGroups) {
    var formData = new FormData()
    formData.append("title", value)
    axios
      .put(process.env.GATSBY_REACT_APP_API_HOST + `/breakoutgroups/${id}/`, formData, { headers: login_credentials })
      .then((res) => {
        let group_data = [...breakoutGroups]
        group_data[index].title = res.data.title
        setBreakoutGroupList(group_data)
      })
      .catch((error) => {
        showErrorMessage(error)
      })
  }

  function getSelectionStatus(id, arr) {
    let flag = 0
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id) {
          flag = 1
        }
      }
    }
    if (flag === 1) {
      return <FontAwesomeIcon icon={faCheck} className={`ml-auto`} />
    }
  }

  function commonItemsData() {
    let topic_id = selectedTemplate,
      module_id = 0,
      data = 0
    return (
      <div className={`d-inline-flex w-100 align-items-center`}>
        <div className={`w-100`} style={{}}>
          <div
            className={`d-inline-flex w-100 align-items-center py-1`}
            style={{
              borderBottom: "1px solid #929BBD80",
              boxShadow: "0px 3px 12px #00000099",
            }}
          >
            <p className={`mb-0 ml-2`} style={{ color: "#E0E2E5", fontSize: "16px", fontWeight: "500" }}>
              Add common items for groups
            </p>
            <img
              onClick={() => {
                setShowCommonItemsModal(false)
                setDisabledResources([])
                setBreakoutGroupInstructionsFetched(false)
                setOpenInstructions(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`ml-auto mr-2 mb-0 pointer`}
              style={{ objectFit: `contain`, height: `18px` }}
            />
          </div>
          <div className={`w-100 px-2 py-3`} style={{ maxHeight: "75vh", overflowY: "scroll" }}>
            <div
              className={`w-100 mb-2`}
              style={{
                backgroundColor: "#303C54",
                borderRadius: "9px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                className={`d-inline-flex w-100 align-items-center px-2 py-2 pointer`}
                onClick={() => {
                  setOpenInstructions(!openInstructions)
                }}
                style={{
                  borderBottom: openInstructions ? "1px solid #FFFFFF40" : "",
                }}
              >
                <FontAwesomeIcon icon={faCommentAlt} className={`mr-2`} color="#E0E2E5" />
                <p className={`mb-0 ml-2`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                  Add general Instructions
                </p>
                <FontAwesomeIcon icon={openInstructions ? faChevronCircleUp : faChevronCircleDown} className={`ml-auto`} color="#E0E2E5" />
              </div>
              {openInstructions ? (
                breakoutGroupInstructionsFetched ? (
                  breakoutGroupInstructions.length > 0 ? (
                    breakoutGroupInstructions.map((instruction, i_indx) => {
                      return (
                        <div
                          className={`w-100 pb-2`}
                          key={`instruction` + instruction.id}
                          style={{
                            borderBottom: i_indx + 1 < breakoutGroupInstructions.length ? "1px solid #FFFFFF60" : "",
                          }}
                        >
                          <InputGroup
                            className="py-0 d-block "
                            style={{
                              borderRadius: "9px",
                              border: "1px solid transparent",
                            }}
                          >
                            <InputGroup.Prepend
                              className="border-0 d-inloine-flex w-100 align-items-center"
                              style={{
                                backgroundColor: "#303C54",
                                borderRadius: "9px 0px 0px 9px",
                              }}
                            >
                              <InputGroup.Text
                                className="border-0 d-flex justify-content-center pb-0"
                                style={{
                                  backgroundColor: "#303C54",
                                  borderRadius: "9px 0px 0px 9px",
                                  color: "#E0E2E5",
                                  opacity: "41%",
                                  fontSize: "16px",
                                }}
                                id="basic-addon1"
                                onClick={() => {
                                  document.getElementById(`instruction` + instruction.id).focus()
                                }}
                              >
                                Type Description
                              </InputGroup.Text>
                              {isPermitted && (
                                <img
                                  src={DeleteIcon}
                                  className={`ml-auto pointer pt-2 pr-2`}
                                  onClick={(e) => {
                                    deleteBreakoutGroupsResources(instruction.id, i_indx, "instruction")
                                  }}
                                />
                              )}
                            </InputGroup.Prepend>
                            <FormControl
                              className={`border-0 d-flex pt-0 w-100 justify-content-center shadow-none  text-white`}
                              style={{
                                backgroundColor: "#303C54",
                                borderRadius: "0px",
                                fontSize: "16px",
                              }}
                              as="textarea"
                              type="text"
                              name="instruction"
                              defaultValue={instruction.instruction}
                              onChange={(e) => {
                                e.preventDefault()
                                let dummy = { ...instructionsLoader }
                                let text = `instruction_` + instruction.id
                                dummy[text] = true
                                setInstructionsLoader(dummy)
                                delayedQuery(e.target.value, selectedTemplate, instruction, i_indx, "instruction", null, breakoutGroupInstructions)
                              }}
                              id={`instruction` + instruction.id}
                              placeholder=""
                            />
                          </InputGroup>
                          <div className={`d-inline-flex w-100 align-items-center px-2 pb-2`} style={{}}>
                            <Dropdown className={`breakout-groups`}>
                              <Dropdown.Toggle
                                size="lg"
                                className={`border-0 px-2 py-1 d-inline-flex align-items-center ${StylesA.btn_focus}`}
                                id="dropdown_list"
                                style={{
                                  fontSize: "14px",
                                  backgroundColor: "#586886",
                                  borderRadius: "4px",
                                }}
                              >
                                {instruction?.breakout_groups?.length > 0 ? (
                                  instruction?.breakout_groups?.map((grp, indx) => {
                                    return (
                                      <p
                                        key={`${instruction.id}_selected_groups_${indx}`}
                                        className={`mb-0 ${indx + 1 == instruction?.breakout_groups.length ? "pr-3" : "pr-2"}`}
                                      >
                                        {grp.title}
                                        {`${indx + 1 < instruction?.breakout_groups?.length ? "," : ``}`}
                                      </p>
                                    )
                                  })
                                ) : (
                                  <p className={`mb-0 pr-1`}>All Groups</p>
                                )}
                                <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className={`br-9px breakout-groups`}>
                                {breakoutGroupList.length > 0 ? (
                                  breakoutGroupList.map((group, index) => {
                                    return (
                                      <Dropdown.Item
                                        className={`${StylesB.dropitem} text-white fs-14px pl-3 py-1 d-inline-flex`}
                                        style={{ minWidth: `max-content` }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          updateCommomResourceInstruction(
                                            "breakout_groups",
                                            selectedTemplate,
                                            instruction,
                                            i_indx,
                                            "breakout_groups_instruction",
                                            group.id
                                          )
                                        }}
                                      >
                                        <p className={`mb-0 w-100 d-inline-flex align-items-center`}>
                                          {group.title}
                                          {getSelectionStatus(group.id, instruction?.breakout_groups)}
                                        </p>
                                      </Dropdown.Item>
                                    )
                                  })
                                ) : (
                                  <p
                                    className={`text-center mb-0 mx-auto py-2`}
                                    style={{
                                      color: "#E0E2E5",
                                      fontSize: "14px",
                                    }}
                                  >
                                    No groups created yet
                                  </p>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <p
                              className="mb-0 ml-auto"
                              style={{
                                fontSize: "14px",
                                color: "#E0E2E5",
                                bottom: "10px",
                                right: "10px",
                              }}
                            >
                              <span className="mb-0 ml-2" style={{ fontSize: "14px" }}>
                                {instructionsLoader[`instruction_` + instruction.id] ? (
                                  <span className={`bg-transparent border-0`} id={`basic-addon`}>
                                    <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                                  </span>
                                ) : (
                                  <img
                                    src={CheckIcon}
                                    alt={`changes_saved_check`}
                                    className={`ml-auto mb-0 mr-1`}
                                    style={{
                                      objectFit: `contain`,
                                      height: `11px`,
                                    }}
                                  />
                                )}
                                All changes are saved
                              </span>
                            </p>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className={`w-100`}>
                      <p className={`w-100 py-3 mb-0 text-center`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                        No instructions created.
                      </p>
                    </div>
                  )
                ) : (
                  <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
                    <Spinner animation="border" variant="secondary" size="sm" />
                  </Col>
                )
              ) : null}
            </div>
            {openInstructions ? (
              <p
                className={`mb-3 pointer`}
                onClick={() => {
                  createCommomResourceInstruction()
                }}
                style={{
                  color: "#D3D5E2",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <u>{breakoutGroupInstructions.length > 0 ? `Add more Instructions` : `Add Instructions`}</u>
              </p>
            ) : null}

            <div
              key={"div2"}
              className={`px-0`}
              style={{
                backgroundColor: "#303C54",
                borderRadius: "8px",
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              <div
                style={{
                  borderRadius: "8px 8px 0px 0px",
                  borderBottom: `1px dashed #838b9e`,
                  padding: "8px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    color: "#E0E2E5",
                    lineHeight: 1,
                    opacity: 0.6,
                  }}
                >
                  Common Resources
                </p>
              </div>
              {showCommonResources()}
              <div
                className="d-flex p-1"
                style={{
                  borderTop: `1px dashed #838b9e`,
                  borderRadius: "0px 0px 8px 8px",
                }}
              >
                <OverlayTrigger overlay={<Tooltip>Add video</Tooltip>}>
                  {/*<img onClick={(e)=>{openNavWithResourceSection('Resource','video');uploadDocs(e,topic_id,module_id,data,false); showSection('video')}} src={multimedia} alt={`add Video button`} title={`Add Videos`} className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`} style={{ objectFit: `contain`, width: `21px`, height: `21px` }}/>*/}
                  <FontAwesomeIcon
                    icon={faVideo}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "video")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, false, "video")
                    }}
                    title={`Add Videos`}
                    alt={`Add Videos`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add blog</Tooltip>}>
                  <img
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "blog")
                      uploadDataProps(topic_id, module_id, breakoutGroupResources, "")
                      setAddBlog(true)
                    }}
                    src={WhiteBlogIcon}
                    title={`Add Blog`}
                    alt={`add blog button`}
                    className={`${
                      leftNavOpen && activeResourceType === "blog" ? StylesB.resource_select_icon_active : StylesB.resource_select_icon
                    } my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add documents</Tooltip>}>
                  <img
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "document")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, false, "document")
                    }}
                    src={documents}
                    alt={`Add Documents`}
                    title={`Add Documents(Doc, Excel, PPT, PDF, Google Docs Link)`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add images</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faImage}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "turientimage")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, true, "turientimage")
                    }}
                    title={`Add Images`}
                    alt={`Add Images`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>Add links</Tooltip>}>
                  <FontAwesomeIcon
                    icon={faLink}
                    onClick={(e) => {
                      openNavWithResourceSection("Resource", "link")
                      uploadDocs(e, topic_id, module_id, breakoutGroupResources, false, "link")
                    }}
                    title={`Add Links`}
                    alt={`Add Links`}
                    className={`${StylesB.resource_select_icon_active} my-auto mx-1 pointer`}
                    style={{
                      objectFit: `contain`,
                      width: `17px`,
                      height: `17px`,
                      color: "#fff",
                    }}
                  />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function showCommonResources() {
    let next_url = null
    let see_more_loader = false
    let topic_id = selectedTemplate,
      module_id = 0,
      data = breakoutGroupResources

    if (breakoutGroupResources) {
      return (
        <Droppable droppableId={`${selectedTemplate}_groupresource`} type={`resourceDraggable`} isDropDisabled={!isPermitted}>
          {(provided, snapshot) => (
            <div
              id={`drop-area`}
              onDragEnd={onDragEnd}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={(e) => onDrop(e, module_id, topic_id, data)}
              className={`bg-transparent px-4 ${Styles.opacity_1}`}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {breakoutGroupResources.map((common_resource, index) => {
                let item = common_resource.resource
                let { typeId, imgObj } = getImageToBeRendered(item)
                return (
                  <>
                    <Draggable
                      key={item.id + `_groupresource_name_` + index}
                      draggableId={item.id.toString() + `-groupresource` + "_" + index.toString()}
                      index={index}
                      className={`${item.resource_data !== null ? "" : "d-none1"} `}
                      isDragDisabled={!isPermitted}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={item.resource_data !== null ? "resource-item" : "d-none1"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getResourceItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div
                            className={`pr-2 d-inline-flex w-100`}
                            style={{
                              color: `#E0E2E5`,
                              background: "#4F6080",
                              justifyContent: "space-between",
                            }}
                          >
                            <div className="d-flex" style={{ alignItems: "center" }}>
                              <FontAwesomeIcon
                                className={`${modulesResourcesFetched} ${topicsResourcesFetched} ml-1 ${item.resource_type ? `d-none` : ``}`}
                                icon={faSpinner}
                                size="lg"
                              />
                              {item.resource_data ? (
                                item.resource_data.thumbnail_url ? (
                                  <LazyLoadImage
                                    className={`my-auto`}
                                    src={item.resource_data.thumbnail_url}
                                    alt={item.name}
                                    style={{
                                      width: `28px`,
                                      height: `20px`,
                                      fontSize: `12px`,
                                    }}
                                  />
                                ) : (
                                  <>
                                    {typeId === 1 ? (
                                      <FontAwesomeIcon
                                        icon={imgObj}
                                        className={`my-0`}
                                        style={{
                                          color: "#67edfd",
                                          marginBottom: 0,
                                          width: `28px`,
                                          height: `20px`,
                                        }}
                                      />
                                    ) : (
                                      <LazyLoadImage
                                        className={`my-auto`}
                                        src={imgObj}
                                        alt={item.name}
                                        style={{
                                          width: `28px`,
                                          height: `20px`,
                                          fontSize: `12px`,
                                        }}
                                      />
                                    )}
                                  </>
                                )
                              ) : (
                                ``
                              )}
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: "0px 0px 0px 10px",
                                }}
                              >
                                {item.resource_data ? item.resource_data?.display_name ?? item.resource_data?.name : item.name}
                              </p>
                            </div>
                            <div className={`d-inline-flex py-1 align-items-center`}>
                              <Dropdown className={`breakout-groups ml-auto mr-2`}>
                                <Dropdown.Toggle
                                  size="lg"
                                  className={`border-0 px-2 py-1 d-inline-flex align-items-center ${StylesA.btn_focus}`}
                                  id="dropdown_list"
                                  style={{
                                    fontSize: "14px",
                                    backgroundColor: "#303C54",
                                    borderRadius: "4px",
                                  }}
                                >
                                  {common_resource?.breakout_groups?.length > 0 ? (
                                    common_resource?.breakout_groups?.map((grp, indx) => {
                                      return (
                                        <p
                                          key={`${common_resource.id}_selected_groups_${indx}`}
                                          className={`mb-0 ${indx + 1 == common_resource?.breakout_groups.length ? "pr-3" : "pr-2"}`}
                                        >
                                          {grp.title}
                                          {`${indx + 1 < common_resource?.breakout_groups?.length ? "," : ``}`}
                                        </p>
                                      )
                                    })
                                  ) : (
                                    <p className={`mb-0 pr-1`}>All Groups</p>
                                  )}
                                  <FontAwesomeIcon icon={faAngleDown} color="#E0E2E5" style={{ height: "15px", width: "15px" }} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`br-9px breakout-groups`}>
                                  {breakoutGroupList.length > 0 ? (
                                    breakoutGroupList.map((group, b_index) => {
                                      return (
                                        <Dropdown.Item
                                          className={`${StylesB.dropitem} text-white fs-14px pl-3 py-1 d-inline-flex`}
                                          style={{ minWidth: `max-content` }}
                                          onClick={(e) => {
                                            e.preventDefault()
                                            updateCommomResourceInstruction(
                                              "breakout_groups",
                                              selectedTemplate,
                                              common_resource,
                                              index,
                                              "breakout_groups",
                                              group.id
                                            )
                                          }}
                                        >
                                          <p className={`mb-0 w-100 d-inline-flex align-items-center`}>
                                            {group.title}
                                            {getSelectionStatus(group.id, common_resource?.breakout_groups)}
                                          </p>
                                        </Dropdown.Item>
                                      )
                                    })
                                  ) : (
                                    <p
                                      className={`text-center mb-0 mx-auto py-2`}
                                      style={{
                                        color: "#E0E2E5",
                                        fontSize: "14px",
                                      }}
                                    >
                                      No groups created yet
                                    </p>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              {isPermitted && (
                                <img
                                  src={DeleteIcon}
                                  onClick={(e) => {
                                    deleteBreakoutGroupsResources(common_resource.id, index, "resource")
                                  }}
                                  alt={"resource delete icon"}
                                  className={`my-auto pointer`}
                                  style={{ objectFit: `contain`, height: `13px` }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  </>
                )
              })}
              {provided.placeholder}
              <React.Fragment>
                <div className={`justify-content-center pt-4 pb-3 d-flex bg-transparent`}>
                  {/*<p className={`pl-3`} >
                    <span className={`${Styles.font_14}`} style={{ color: `#E0E2E5` }}>Drag and drop media/document files to add content</span>
                  </p>*/}
                  <div className={`${Styles.drop_area} m-0 px-0 py-0 bg-transparent  text-center ${files.length === 0 ? `` : `border-0`}`}>
                    <div className={`w-100 col-12`}>
                      <Form className="my-form mb-0">
                        <div>
                          {/* onClick={(e)=>{setUploadInTopic(id);setUploadInModule(module_id);setUploadDataSequence(data.length === 0 ? 0 : parseFloat(data[data.length - 1].sequence).toFixed(2))}} >*/}
                          {isPermitted && (
                            <p className={`${StylesM.font_14} mb-2`}>
                              Drag and drop media/document files to add content
                              {/*/&nbsp;<label className={`button btn border-0 px-0 ${StylesM.font_14}`} style={{ color:"#E0E2E5",textDecoration:'underline'}} htmlFor="fileElem">Browse</label>*/}
                            </p>
                          )}
                          {/*<p className={`${StylesM.font_14} mb-4`} >Files Supported: MP4/ PDF/ XLS/ JPG/PNG</p>*/}
                        </div>
                        {files.length === 0 ? (
                          <input
                            className={`d-none`}
                            type="file"
                            id={"fileElem_" + topic_id}
                            multiple
                            accept="image/*,application/pdf,video/*,.doc,.docx,.ppt,.pptx,.xlsx,.xls"
                            onClick={(e) => {
                              clickedFileInput(e)
                            }}
                            onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              fileInputClicked(e, topic_id, module_id, data)
                              validateAndAdd(e)
                            }}
                            disabled={!isPermitted}
                          />
                        ) : null}
                      </Form>
                    </div>
                    {uploadInModule !== null && uploadInTopic !== null && uploadDataSequence !== null && uploadInTopic === topic_id ? (
                      <UploadFiles
                        files={files}
                        setFiles={(value) => setFiles(value)}
                        fetchTopicResources={(id) => fetchBreakoutGroupsResources()}
                        showSection={showSection}
                        setShowSection={setShowSection}
                        refetchLeftSideResourcesBar={refetchLeftSideResourcesBar}
                        properties={{
                          module: "Common Items Group",
                          template: uploadInTopic,
                          mainId: uploadInTopic,
                          itemName: " ",
                          add_to_group_resource: "true",
                          sequence: uploadDataSequence,
                          draft_content: hashValue === "real" ? `false` : `true`,
                          stage_id: selectedStage,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            </div>
          )}
        </Droppable>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }

  function creatingGroupsData() {
    // if(breakoutGroupList.length > 0) {
    return (
      <div className={`d-inline-flex w-100 align-items-center`}>
        <div className={`w-100`}>
          <div className={`d-inline-flex w-100 align-items-center py-1`} style={{ borderBottom: "1px solid #929BBD80" }}>
            <p className={`mb-0 ml-2`} style={{ color: "#E0E2E5", fontSize: "18px" }}>
              Create Groups
            </p>
            <img
              onClick={() => {
                setShowCreatingGroupsModal(false)
              }}
              src={CancelWhite}
              alt={`Cancel`}
              className={`ml-auto mr-2 mb-0 pointer`}
              style={{ objectFit: `contain`, height: `18px` }}
            />
          </div>
          <div className={`w-100 px-2 py-3`}>
            {breakoutGroupList.length > 0 ? (
              breakoutGroupList.map((group, index) => {
                return (
                  <div
                    key={`group_item_${group.id}`}
                    className={`mb-1 d-inline-flex align-items-center w-100`}
                    style={{
                      backgroundColor: "#303C54",
                      borderRadius: "9px",
                      boxShadow: "0px 3px 6px #00000029",
                    }}
                  >
                    <input
                      type="text"
                      name="title"
                      id={group.id + `-group-input-id`}
                      defaultValue={group.title}
                      placeholder={"Enter group Title"}
                      onChange={(e) => {
                        e.preventDefault()
                        delayedQuery(e.target.value, 0, group, index, "group_updation", null, breakoutGroupList)
                      }}
                      className={`${StylesQ.question_title_input} ${StylesQ.question_title_input_size} ${!isPermitted && "disable-content"} form-control px-3`}
                      required
                    />
                    {isMuralEnabled && (
                      <ActivityRoomWokSpace
                        mural_workspace={course_template.activity_workspace}
                        activity_workspace={group?.id ? group : {}}
                        type={"group"}
                        groupIndex={index}
                        breakoutGroups={breakoutGroupList}
                        setBreakoutGroupList={setBreakoutGroupList}
                        mural_account={course?.mural_account}
                        isPermitted={isPermitted}
                      />
                    )}
                    {isPermitted && (
                      <img
                        src={DeleteIcon}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          deleteBreakoutGroup(group.id)
                        }}
                        alt={`group delete icon`}
                        className={`mr-2 mb-0 pointer ${!isMuralEnabled && "ml-auto"}`}
                        style={{ objectFit: `contain`, height: `18px` }}
                      />
                    )}
                  </div>
                )
              })
            ) : (
              <p className={`text-center mx-auto py-3`} style={{ color: "#E0E2E5", fontSize: "14px" }}>
                No groups created yet
              </p>
            )}
            {isPermitted && (
              <div className={`d-inline-flex w-100 align-items-center pt-2`}>
                <Button
                  className={`${StylesB.add_button} border_on_hover mr-2 d-flex `}
                  variant="none"
                  onClick={() => {
                    setShowCreatingGroupsModal(false)
                  }}
                >
                  I am done
                </Button>
                <Button
                  className={`${StylesB.add_button} border_on_hover d-flex `}
                  variant="none"
                  onClick={(e) => {
                    createBreakoutGroup(e)
                  }}
                >
                  Add Group
                </Button>
                <Button
                  className={`${StylesB.add_button} border_on_hover ml-2 d-flex `}
                  variant="none"
                  onClick={(e) => {
                    setShowCommonItemsModal(true)
                    setDisabledResources(disabledResourceType)
                  }}
                >
                  {breakoutGroupsResourcesFetched && breakoutGroupResources.length > 0 ? `Edit common items` : `Add common items`}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (showCreatingGroupsModal === false) {
      setRefetchGroups(true)
    }
  }, [showCreatingGroupsModal])

  return (
    <div>
      <SEO title="Course Creation" />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onDragUpdate={onDragUpdate}>
        <Modal show={dmodal} onHide={handleClose} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
          <div className={`${StylesB.delete_modal_header}`}>
            <img
              onClick={handleClose}
              src={CancelWhite}
              alt={`Cancel`}
              className={`my-auto mr-1 pointer`}
              style={{
                objectFit: `contain`,
                height: `13px`,
                position: "absolute",
                top: "7px",
                right: "5px",
              }}
            />
            <img src={TrashCan} alt={`delete modal delete icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain`, height: `37px` }} />
            <div>
              <p style={{ fontWeight: "bold", fontSize: "15px" }}>Delete</p>
              <p>Item will be deleted permanentley!</p>
            </div>
          </div>
          <Modal.Body
            className="text-center"
            style={{
              backgroundColor: "#121b2b",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <p
              style={{
                color: "#E0E2E5",
                fontSize: "15px",
                marginBottom: "10px",
              }}
            >
              {" "}
              Are you sure you want to delete{" "}
              {selectedDeletingModule && selectedDeletingModule.name !== undefined
                ? selectedStageName === "Workshop" && course.course_type !== "1"
                  ? "session "
                  : "module " + selectedDeletingModule.name
                : ""}{" "}
              {selectedDeletingTopic && selectedDeletingTopic.name !== undefined && selectedDeletingTopic.visible_as_resource === false
                ? `topic ` + selectedDeletingTopic.name
                : ""}{" "}
              ?{" "}
            </p>
            <div className="d-flex justify-content-center">
              <Button
                className={`${StylesB.add_button_large} border_on_hover mr-3`}
                variant="secondary"
                onClick={deleteModule}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                Yes
              </Button>
              <Button
                className={`${StylesB.add_button_large} border_on_hover`}
                variant="secondary"
                onClick={handleClose}
                style={{ width: "75px", border: "1px solid transparent" }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showInstructionCreatingGroupsModal}
          onHide={() => {
            setShowInstructionCreatingGroupsModal(false)
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <Modal.Body className="p-0" style={{ backgroundColor: "#212C42", borderRadius: "9px" }}>
            <div className={`d-inline-flex w-100 align-items-center px-3 py-3`}>
              <div className={`w-75`}>
                <p
                  className={`mb-2`}
                  style={{
                    color: "#E0E2E5",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  You don’t have any groups created
                </p>
                <p style={{ color: "#E0E2E5", fontSize: "12px" }}>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                  diam voluptua.{" "}
                </p>
                <div className={`d-inline-flex w-100 align-items-center`}>
                  <Button
                    disabled={!createdGroup}
                    className={`${StylesB.add_button} border_on_hover d-flex `}
                    variant="none"
                    onClick={(e) => {
                      setShowInstructionCreatingGroupsModal(false)
                      setShowCreatingGroupsModal(true)
                    }}
                  >
                    {createdGroup ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                    Create Groups Now
                  </Button>
                  <Button
                    className={`${StylesB.add_button} border_on_hover d-flex ml-2`}
                    variant="none"
                    onClick={() => {
                      setShowInstructionCreatingGroupsModal(false)
                    }}
                  >
                    Do it Later
                  </Button>
                </div>
              </div>
              <div className={`w-25`}>
                <img src={BreakoutGroupsCreation} alt={`groups creation icon`} className={`my-auto mr-2 pointer`} style={{ objectFit: `contain` }} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showAddDescriptionmodal}
          size="lg"
          onHide={() => {
            setShowAddDescriptionModal(false)
          }}
          centered
          className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}
        >
          <Modal.Body
            className="text-center py-0"
            style={{
              backgroundColor: "#212C42",
              borderRadius: "8px",
              border: "1px solid #707070",
              color: "#E0E2E5",
            }}
          >
            <Col className={`w-100 align-items-center d-inline-flex`}>
              <div className={`w-100 pt-3 pb-2`}>
                {course?.course_type === "1" ? (
                  <DescriptionInputField
                    datum={activeDatum}
                    handleSave={handleSave}
                    moduleEdited={moduleEdited}
                    course={course}
                    selectedStageName={selectedStageName}
                    showModalHandler={(arg) => {
                      setShowAddDescriptionModal(arg)
                    }}
                    isPermitted={isPermitted}
                  />
                ) : (
                  <TextareaDescriptionField
                    datum={activeDatum}
                    handleSave={handleSave}
                    moduleEdited={moduleEdited}
                    course={course}
                    selectedStageName={selectedStageName}
                    showModalHandler={(arg) => {
                      setShowAddDescriptionModal(arg)
                    }}
                    isPermitted={isPermitted}
                  />
                )}
              </div>
            </Col>
          </Modal.Body>
        </Modal>
        <Modal show={showSuggestDayModal} centered className={`${StylesB.classroom_delete_modal} classroom-resource-delete-modal`}>
          <CloseBtn onClick={() => setShowSuggestDayModal(false)} />
          <div className={`${StylesB.delete_modal_header} align-items-center pr-4`}>
            <FontAwesomeIcon className={`my-auto mr-2 pointer`} color="#717886" style={{ height: "50px", width: "50px" }} icon={faClock} />
            <div>
              <p className={`pl-2`} style={{ fontSize: "18px", color: "#E0E2E5" }}>
                More than 8 hours of day {recentDay} has been scheduled.
              </p>
            </div>
          </div>
          <Modal.Body
            className="text-center"
            style={{
              backgroundColor: "#121b2b",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <div className={`text-center align-items-center`}>
              <p
                style={{
                  color: "#E0E2E5",
                  fontSize: "15px",
                  marginBottom: "10px",
                }}
              >
                Do you want to continue with day {recentDay} {recentDay < courseDays ? `or start on day ${recentDay + 1}` : ""} ?{" "}
              </p>
              <div className={`px-5`} style={{ display: "grid" }}>
                <button
                  className={`px-4 py-1 mb-2 border_on_hover mx-5`}
                  onClick={(e) => {
                    setSuggestedDay(e, recentDay)
                  }}
                  style={{
                    backgroundColor: "#586886",
                    borderRadius: "9px",
                    border: "1px solid #586886",
                    color: "#E0E2E5",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Continue with day {recentDay}
                </button>
                {recentDay < courseDays ? (
                  <button
                    className={`px-4 py-1 border_on_hover mx-5`}
                    onClick={(e) => {
                      setSuggestedDay(e, recentDay + 1)
                    }}
                    style={{
                      backgroundColor: "#586886",
                      borderRadius: "9px",
                      border: "1px solid #586886",
                      color: "#E0E2E5",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    Start with day {recentDay + 1}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {cohortScheduleModal()}
        <Sidebar
          activeResourceType={activeResourceType}
          setActiveResourceType={(value) => setActiveResourceType(value)}
          activeNavItem={activeNavItem}
          setActiveNavItem={(value) => setActiveNavItem(value)}
          leftNavOpen={leftNavOpen}
          toggleLeftNav={toggleLeftNav}
          enableDND={true}
          allResourcesChanged={allResourcesChanged}
          allResources={allResources}
          allResourcesNext={allResourcesNext}
          setAllResource={(value) => setAllResources(value)}
          currentSelectedResourcesType={currentSelectedResourcesType}
          setHomepageActiveResource={(value) => setCurrentSelectedResourcesType(value)}
          setHomepageAllResource={(value) => setAllResources(value)}
          currentNavTab={currentNavTab}
          setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
          showPollModal={showPollModal}
          showPopQuizModal={showPopQuizModal}
          disabledResources={disabledResources}
        />
        <LayoutHeader />
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="12"
          onClick={(e) => setLeftNavOpen(false)}
          className="px-0 d-flex"
          style={{
            width: "calc(100vw - 60px)",
            justifyContent: leftNavOpen ? "flex-end" : "center",
            minHeight: "calc(100vh - 60px)",
          }}
        >
          <div
            className={`${leftNavOpen ? "" : "col-10"} mt-2 d-flex px-2`}
            style={{
              alignItems: leftNavOpen ? "flex-end" : "center",
              flexDirection: "column",
              width: leftNavOpen ? "calc(100vw - 380px)" : "calc(100vw - 60px)",
              height: "fit-content",
            }}
          >
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              onMouseOver={(e) => {
                e.stopPropagation()
                e.preventDefault()
                let ele = document.getElementById("sections")
                if (ele !== null && ele !== undefined) {
                  if (ele.scrollWidth > ele.clientWidth) {
                    setHovered(true)
                  }
                }
              }}
              className={`${StylesB.course_creation_header} d-flex px-0`}
            >
              <p
                className="mb-0 py-2 px-3 font-weight-bold text-color-1"
                id="coursename"
                style={{
                  borderRadius: "9px 0px 0px 9px",
                  lineHeight: "1",
                  minWidth: "max-content",
                }}
                title={course.name}
              >
                {courseName}
              </p>
              {findScrollableOrNot() && hovered ? (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollLeft()
                  }}
                  className="pointer"
                  icon={faChevronLeft}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollLeft()
                  }}
                  className="pointer"
                  icon={faChevronLeft}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                    visibility: "hidden",
                  }}
                />
              )}
              <div id="sections" className="d-flex align-items-center hide_scrollbar" style={calculateWidth()}>
                <Link
                  className={`pointer ${StylesB.backgroundonhover}`}
                  style={{ color: "#E0E2E5", minWidth: "max-content" }}
                  to={`/app/companies/${course.company}/courses/edit/${course.id}`}
                >
                  <p className="mb-0 pl-14px pr-14px pointer pt-2 pb-2 fs-16px" id="course_detail">
                    Course Details
                  </p>
                </Link>
                <Link
                  className={`pointer ${StylesB.backgroundonhover}`}
                  style={{ color: "#E0E2E5", minWidth: "max-content" }}
                  to={`/app/companies/${course.company}/courses/course-certification/${course.id}`}
                >
                  <p className="mb-0 pl-14px pr-14px pointer pt-2 pb-2 fs-16px" id="course_certification">
                    Certifications
                  </p>
                </Link>
                <p
                  className={`${StylesB.course_nav_item_active} mb-0 pointer py-2 pl-14px pr-14px fs-16px font-weight-bold`}
                  style={{ color: "#E0E2E5", minWidth: "max-content" }}
                  id="course_content"
                >
                  Course Content
                </p>
                <p
                  onClick={() => navigateToPublish()}
                  className={`${StylesB.backgroundonhover} mb-0 pointer py-2 pl-14px pr-14px fs-16px`}
                  style={{ color: "#E0E2E5", minWidth: "max-content" }}
                  id="course_publish"
                >
                  Publish Course
                </p>
              </div>
              {findScrollableOrNot() && hovered ? (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollRight()
                  }}
                  className="pointer"
                  icon={faChevronRight}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  onClick={() => {
                    scrollRight()
                  }}
                  className="pointer"
                  icon={faChevronRight}
                  size="lg"
                  style={{
                    objectFit: `contain`,
                    height: `20px`,
                    color: `#E0E2E551`,
                    visibility: "hidden",
                  }}
                />
              )}
              <Image
                src={CancelWhite}
                alt={`close`}
                width={20}
                height={20}
                onClick={() => {
                  navigate("/app/dashboard")
                }}
                className={`pointer mr-3 ml-auto float-right align-self-center`}
              />
            </Col>
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              onMouseOver={() => {
                setHovered(false)
              }}
              className="px-0"
            >
              {showMainSection()}
            </Col>
          </div>
          {selectedTemplate && createBreakoutSessionOpened ? (
            <div
              style={{
                position: "fixed",
                top: "0px",
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                className="d-flex"
                style={{
                  justifyContent: leftNavOpen ? "flex-end" : "center",
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                <Col xs="12" sm="12" md="8" lg="9">
                  <TestBreakoutSession
                    parent_page={"course_content"}
                    setLeftNavOpen={(value) => setLeftNavOpen(value)}
                    setCurrentNavTab={(tab) => setCurrentNavTab(tab)}
                    leftNavOpen={leftNavOpen}
                    allResourcesChanged={allResourcesChanged}
                    allResources={allResources}
                    setHomepageAllResource={(value) => setAllResources(value)}
                    openNavWithResourceSection={openNavWithResourceSection}
                    currentSelectedResourcesType={currentSelectedResourcesType}
                    resetDragState={() => {
                      setDraggedItem("")
                      setDragEnded(false)
                    }}
                    dragEnded={dragEnded}
                    draggedItem={draggedItem}
                    template_id={selectedTemplate}
                    course_real_template_id={course?.course_template?.id}
                    activeSection={activeSection}
                    setActiveSection={(item) => setActiveSection(item)}
                    handleCloseBreakoutOverlay={handleCloseBreakoutOverlay}
                    topic_id={selectedTopicForBreakout}
                    activeBreakoutSession={selectedBreakoutForEdit}
                    setCreatedBreakoutSession={(item) => setCreatedBreakoutSession(item)}
                    showAllBreakoutSessions={showAllBreakoutSessions}
                    openCreatingGroupsModal={() => {
                      if (breakoutGroupList.length === 0) {
                        setShowInstructionCreatingGroupsModal(true)
                      }
                    }}
                    refetchBreakoutGroups={refetchGroups}
                    draft_content={hashValue === "real" ? `false` : `true`}
                    selectedStage={selectedStage}
                    setShowPollModal={setShowPollModal}
                    setShowPopQuizModal={setShowPopQuizModal}
                    setDisabledResources={setDisabledResources}
                    isPermitted={isPermitted}
                  />
                </Col>
              </Col>
            </div>
          ) : (
            ""
          )}
          {showCreatingGroupsModal ? (
            <div
              style={{
                position: "fixed",
                top: "0px",
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#00000090",
              }}
            >
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                className="d-inline-flex w-100 pr-5"
                style={{
                  justifyContent: leftNavOpen ? "flex-end" : "center",
                  maxHeight: "90vh",
                }}
              >
                <Col xs="12" sm="12" md="8" lg="9" style={{ maxWidth: "60%" }}>
                  <div
                    className="p-0"
                    style={{
                      backgroundColor: "#212C42",
                      borderRadius: "9px",
                      border: "1px solid #929BBD",
                    }}
                  >
                    {creatingGroupsData()}
                  </div>
                </Col>
              </Col>
            </div>
          ) : (
            ""
          )}
          {showCommonItemsModal ? (
            <div
              style={{
                position: "fixed",
                top: "0px",
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                className="d-flex"
                style={{
                  justifyContent: leftNavOpen ? "flex-end" : "center",
                  maxHeight: "90vh",
                  overflowY: "auto",
                }}
              >
                <Col xs="12" sm="12" md="8" lg="9">
                  <div
                    className="p-0"
                    style={{
                      backgroundColor: "#212C42",
                      borderRadius: "9px",
                      border: "1px solid #929BBD",
                    }}
                  >
                    {commonItemsData()}
                  </div>
                </Col>
              </Col>
            </div>
          ) : (
            ""
          )}
        </Col>
      </DragDropContext>
      <Blog
        showBlog={addBlog}
        setShowBlog={setAddBlog}
        topicId={uploadInTopic}
        sequenceId={uploadDataSequence}
        fetchTopicResources={(id) => (addResourceType === "common" ? fetchBreakoutGroupsResources() : fetchTopicResources(id))}
        refetchLeftSideResourcesBar={() => refetchLeftSideResourcesBar("blog")}
        properties={attrForResources}
      />
    </div>
  )
}

export default CourseContent
